import { Controller } from "stimulus";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default class extends Controller {
  static targets = ["croppable", "cropped", "inputCropped", "cropperModal"];
  static values = { squareCrop: Boolean }

  connect() {
    this.element[this.identifier] = this;
  }

  cropGenerator(name) {
    this[`_crop_${name}`] = document.createElement("input");
    this[`_crop_${name}`].name = `[crop_${name}]`;
    this[`_crop_${name}`].type = "hidden";
    this.croppableTarget.parentNode.insertBefore(
      this[`_crop_${name}`],
      this.croppableTarget.nextSibling
    );
  }

  crop_x() {
    if (this._crop_x == undefined) {
      this.cropGenerator("x");
    }
    return this._crop_x;
  }

  crop_y() {
    if (this._crop_y == undefined) {
      this.cropGenerator("y");
    }
    return this._crop_y;
  }
  crop_width() {
    if (this._crop_width == undefined) {
      this.cropGenerator("width");
    }
    return this._crop_width;
  }
  crop_height() {
    if (this._crop_height == undefined) {
      this.cropGenerator("height");
    }
    return this._crop_height;
  }

  createCropper(ratio, rounded) {
    let self = this;
    return new Cropper(this.croppableTarget, {
      dragMode: "move",
      viewMode: 2,
      movable: false,
      rotatable: false,
      scalable: false,
      zoomable: false,
      aspectRatio: ratio,
      ready: () => {
        if (rounded) {
          let borderRadius = '50%'
          if (this.squareCropValue) borderRadius = '0%'
          const cropper_box = document.querySelector(".cropper-view-box");
          cropper_box.style.borderRadius = borderRadius;
          const cropper_face = document.querySelector(".cropper-face");
          cropper_face.style.backgroundColor = "inherit!important";
        }
      },
      crop(event) {
        self.crop_x().value = event.detail.x;
        self.crop_y().value = event.detail.y;
        self.crop_width().value = event.detail.width;
        self.crop_height().value = event.detail.height;
      },
    });
  }

  save(e) {
    e.preventDefault();
    let result = this.cropper.getCroppedCanvas();
    const quality = 1; // La calidad de la imagen va de 0 a 1. Siendo 1 la mayor calidad posible, por defecto esta en 0.92
    result.toBlob((blob) => {
      let filename = this.inputCroppedTarget.files[0].name;
      let file = new File([blob], filename, {
        type: `image/${filename.substring(filename.lastIndexOf(".") + 1)}`,
      });
      let container = new DataTransfer();
      container.items.add(file);
      this.inputCroppedTarget.files = container.files;
    }, "image/jpeg");
    let img = this.croppedTarget;
    img.hidden = false;
    img.src = result.toDataURL("image/jpeg", quality);
    let label = e.target
      .closest("#cropper")
      .querySelector("label.custom-file-label");
    if (label) label.innerHTML = this.inputCroppedTarget.files[0].name;
    let cropperModal = this.cropperModalTarget;
    cropperModal.style.display = "none";
    this.cropper.reset();
  }

  dismiss(e) {
    e.preventDefault();
    let cropperModal = this.cropperModalTarget;
    cropperModal.style.display = "none";
    this.inputCroppedTarget.value = null;
    this.inputCroppedTarget.files = undefined;
    this.cropper.destroy();
  }

  rebuild({ ratio = 2, rounded = false }) {
    this.cropper?.destroy();
    this.cropper = this.createCropper(ratio, rounded);
  }
}
