import { Controller } from "stimulus"

const autocompleteOptions = {
  types: ['address'],
  componentRestrictions: { country: ['AR'] }
}

export default class extends Controller {
  static targets = [ "field", "map", "latitude", "longitude", "province", "locality" ]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(this.data.get("latitude") || -35, this.data.get("longitude") || -58),
      zoom: (!this.data.get("latitude") ? 4 : 16)
    })

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, autocompleteOptions)
    this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

    if (this.data.get("latitude") && this.data.get("longitude")) {
      this.marker = new google.maps.Marker({
        map: this.map,
        position: new google.maps.LatLng(this.data.get("latitude"), this.data.get("longitude"))
      })
      this.marker.setVisible(true)
    } else {
      this.marker = new google.maps.Marker({
        map: this.map,
        anchorPoint: new google.maps.Point(0, -29)
      })
    }
  }

  placeChanged() {
    this.latitudeTarget.value = null
    this.longitudeTarget.value = null
    this.provinceTarget.value = null
    this.localityTarget.value = null

    let place = this.autocomplete.getPlace()

    if (!place || !place.geometry) {
      this.marker.setVisible(false)
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(16)
    }

    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    let province = place.address_components.filter(item => item.types[0] == 'administrative_area_level_1')
    let locality = place.address_components.filter(item => item.types[0] == 'administrative_area_level_2')
    if (locality.length == 0) {
      locality = place.address_components.filter(item => item.types[0] == 'locality')
    }

    if (province[0].short_name == 'CABA') {
      // Se tomó la decisión de poner CABA como localidad cuando Google informa a
      // CABA en el campo que es tradicionalmente de provincia (dejando CABA como provincia también)
      this.provinceTarget.value = province[0].short_name
      this.localityTarget.value = province[0].short_name
    } else {
      if (province[0].short_name == 'Provincia de Buenos Aires') {
        this.provinceTarget.value = 'Buenos Aires'
      } else {
        this.provinceTarget.value = province[0].long_name
      }

      this.localityTarget.value = locality[0].long_name
    }

    this.fieldTarget.value = this.buildFieldValue(place)
    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }

  buildFieldValue(place) {
    return place.address_components.map((item) => item.long_name).join(', ');
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
