import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["icon", "radioButton", "box", "close"]
  static values = { url: String }

  selectRadioIcon(event) {
    let stars = event.currentTarget.id
    this.radioButtonTargets[stars - 1].click()
    this.iconTargets.forEach(each => {
        each.style.fill = "none"
    })
    this.iconTargets.forEach(each => {
        if (each.id <= stars) each.style.fill = "#FF0894"
    })
  }

  showSelected(event) {
    let show = event.currentTarget.id
    this.radioButtonTargets[show].click()
    this.boxTargets.forEach(each => {
      if (each.classList.contains("selected")) each.classList.remove("selected", "border-2", "border-primary-pink")
    })
    this.boxTargets.forEach(each => {
      if (each.id == show) each.classList.add("selected", "border-2", "border-primary-pink");
    })
  }

  closeSurveysModal(event){
    event.stopPropagation()
    //restore first url    
    const button = document.querySelector(`#launch_modal_${this.closeTarget.dataset.id}`).firstElementChild
    button.dataset.modalFrontUrlValue = this.urlValue;
    // close modal
    const body_sur = document.querySelector('body')
    const modal_sur = document.querySelector('.modal')
    modal_sur.classList.toggle('opacity-0')
    modal_sur.classList.toggle('pointer-events-none')
    body_sur.classList.toggle('modal-active')
  }
  
}
    

