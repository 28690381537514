import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  request() {
    const chatbox    = document.getElementById('chatbox');
    const controller = this.application.getControllerForElementAndIdentifier(chatbox, 'chat-popup');
    controller.loadChatBox(this.urlValue);
  }
}
