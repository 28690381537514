import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    serviceId: String,
  }

  connect() {
    if (this.serviceIdValue) this.scrollToService()
  }

  scrollToService() {
    let serviceAccordion = document.getElementById(this.serviceIdValue)
    if (serviceAccordion) serviceAccordion.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}