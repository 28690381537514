import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['emailInput', 'passwordInput', 'passwordConfirmationInput', 'submit']
 
  validPassword = false
  validPasswordConfirmation = false

  connect() { this.submitTarget.disabled = true }

  handleInputs() {
    this.handlePassword();
    this.handlePasswordConfirmation();
    this.handleSubmit();
  }

  handlePassword() {
    length = this.passwordInputTarget.value.length
    if (length > 0 && length <= 8) {
      this.addError(this.passwordInputTarget, 'passwordError')
      this.validPassword = false
    } else {
      this.removeError(this.passwordInputTarget, 'passwordError')
      this.validPassword = true
    }
  }

  handlePasswordConfirmation() {
    if (this.passwordInputTarget.value != this.passwordConfirmationInputTarget.value) {
      this.addError(this.passwordConfirmationInputTarget, 'passwordErrorConfirmation')
      this.validPasswordConfirmation = false
    } else {
      this.removeError(this.passwordConfirmationInputTarget, 'passwordErrorConfirmation')
      if (this.passwordConfirmationInputTarget.value.length > 0) { this.validPasswordConfirmation = true }
    }
  }

  handleSubmit() { this.submitTarget.disabled = (!(this.validPassword && this.validPasswordConfirmation)) }

  addError(target, element) { 
    target.classList.add('border', 'border-danger')
    document.getElementById(element).hidden = false 
  }

  removeError(target, element){
    target.classList.remove('border', 'border-danger')
    document.getElementById(element).hidden = true
  }
}
