import { Controller } from "stimulus";

export default class extends Controller {
  static values = { currentUser: String }

  static targets = [
    "optionsContainer",
    "option",
    "optionSelected",
    "allOptions",
    "optionText",
    "icon"
  ];

  connect() {
    let controller = this
    this.optionSelectedTarget.addEventListener("click", (event) => {
      if (controller.currentUserValue === "true") {
        this.optionsContainerTarget.classList.toggle("active");
        event.stopPropagation(); //Close custom select on click outside
      } else {
        const modalController = controller.application.getControllerForElementAndIdentifier(document.getElementById("alert"),"freemium");
        modalController.freemium();
      }
    });
    this.toggleSelect(); //Close custom select on click outside
  }

  selectHandler() {
    const checkedOptions = this.optionTargets.filter((e) => e.querySelector('input').checked)
    const size = checkedOptions.length

    if (size == 0) {
      this.optionTextTarget.innerHTML = this.allOptionsTarget.value;
      this.allOptionsTarget.checked = true;

      if (this.hasIconTarget) this.iconTarget.classList.add('hidden')
      return;
    }

    this.optionTextTarget.innerHTML = checkedOptions[0].querySelector("label").innerHTML;
    this.optionTextTarget.value = checkedOptions[0].querySelector("label").innerHTML;
    this.allOptionsTarget.checked = false;

    if (this.hasIconTarget) this.iconTarget.classList.remove('hidden')
  }

  toggleSelect() {
    const body = document.querySelector("body");
    body.addEventListener("click", (e) => {
      const option = document.querySelector(".options-container.active");
      if (option && !option.contains(e.target)) {
        option.classList.remove("active");
      }
    });
  }

  checkBoxChange() {
    //Saca el check a las opciones que incluyen
    this.optionTargets.forEach((el) => el.querySelector('input').checked = false);
    this.allOptionsTarget.checked = true;
    this.optionTextTarget.innerHTML = this.allOptionsTarget.value;

    if (this.hasIconTarget) this.iconTarget.classList.add('hidden')
  }
}
