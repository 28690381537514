import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["launcher", "submit", "welcome", "first"];

  call(event) {
    let modal = this.launcherTarget.firstElementChild;
    modal.dataset.modalFrontUrlValue = event.target.dataset.url;
    modal.click();
  }

  welcome() {
    this.welcomeTarget.style.display = "none";
    this.firstTarget.classList.remove("hidden");
  }

  finish () {
    document.querySelector(".modal-close").click();
  }
}
