import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    currentUser: String,
  }

  static targets = ['position', 'professionalCard']

  connect() {
    this.modalListener();
    if (this.hasPositionTarget) this.positionTarget.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }

  modalListener() {
    document.addEventListener('click', (event) => {
      const clickedProfessionalCard = event.target.closest('.professional-card');

      if (clickedProfessionalCard && this.currentUserValue != 'true') {
        event.preventDefault();
        event.stopPropagation();
        const hiddenDivModal = document.getElementsByClassName("hidden-modal")[0];
        hiddenDivModal.classList.remove("hidden");
      }
    })
  }
}