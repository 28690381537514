import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  connect() {
    gtag("event", "Sign_in", {
      event_category: "Render",
      event_label: "Form rendered",
      non_interaction: true,
    });
  }

  submit_sign_in(event) {
    gtag("event", "Sign_in", {
      event_category: "E-Mail",
      event_label: "Sign In Form submitted",
      event_value: event.target["users_login_form[email]"].value,
    });
  }

  submit_sign_in_facebook(event) {
    event.preventDefault();
    gtag("event", "Sign_in", {
      event_category: "Facebook",
      event_label: "Sign In with Facebook",
      event_value: "Facebook",
      event_callback: () =>
        (window.location.href = event.target.closest("a").href),
    });
  }

  submit_sign_in_google(event) {
    gtag("event", "Sign_in", {
      event_category: "Google",
      event_label: "Sign In with Google",
      event_value: "Google",
    });
  }
}
