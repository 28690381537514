import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["bigImage", "previewImages", "bigImageContainer", "buttons"];

  connect() {
    this.previewImagesTargets.forEach((div) => {
      div.addEventListener("click", () => {
        this.removeSelectedClassFromImages();

        div.classList.add("selected");

        const imageURL = div.dataset.imageUrl;
        const bannerId = div.dataset.bannerId;
        this.mostrarImagen(imageURL, bannerId);

        this.bigImageContainerTarget.addEventListener("mouseenter", () => {
          this.showButtons(bannerId);
        });

        this.bigImageContainerTarget.addEventListener("mouseleave", () => {
          this.hideButtons();
        });
      });
    });

    const firstPreviewDiv = this.previewImagesTargets[0];
    if (firstPreviewDiv) {
      const firstImageURL = firstPreviewDiv.dataset.imageUrl;
      const firstBannerId = firstPreviewDiv.dataset.bannerId;
      this.mostrarImagen(firstImageURL, firstBannerId);
    }
  }

  mostrarImagen(imageURL) {
    this.bigImageTarget.innerHTML = `<img src="${imageURL}" alt="Big Image">`;
  }

  showButtons(bannerId) {
    this.buttonsTarget.innerHTML = `${this.showEditBannerButton(bannerId)}${this.showDeleteBannerButton(bannerId)}`;
  }

  hideButtons() {
    this.buttonsTarget.innerHTML = "";
  }

  showDeleteBannerButton(bannerId) {
    const deleteContent = `<p class ="delete-banner-button-content"><i class="fas fa-trash"></i> Eliminar</p>`;
    const deleteBannerButton = `<div data-controller="modal" data-modal-url-value="${this.deleteBannerUrl(bannerId)}" data-action="click->modal#loadModal" class="delete-banner-button">${deleteContent}</div>`;
    return deleteBannerButton;
  }

  showEditBannerButton(bannerId) {
    const editContent = `<p class ="delete-banner-button-content"><i class="fas fa-edit"></i> Editar</p>`;
    const editBannerButton = `<div class="delete-banner-button"> <a href="${this.editFormUrl(bannerId)}" class="text-decoration-none">${editContent}</a></div>`;
    return editBannerButton;
  }

  editFormUrl(bannerId) {
    return `/admin/carousel_slides/${bannerId}/edit_form`;
  }

  deleteBannerUrl(bannerId) {
    return `/admin/carousel_slides/${bannerId}/modal_delete`;
  }

  removeSelectedClassFromImages() {
    this.previewImagesTargets.forEach((div) => {
      div.classList.remove("selected");
    });
  }
}
