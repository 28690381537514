import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.inputTarget.addEventListener('input', this.resizeChatInput.bind(this));
    this.resizeChatInput();
  }

  resizeChatInput() {
    this.inputTarget.style.height = this.inputTarget.scrollHeight + 'px'
  }
}
