import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["inputCheckbox", "inputLabel"];

  connect() {
    this.selectOnConnect(this.inputCheckboxTargets);
  }

  clickInputCheckbox(event) {
    this.toggleSelected(event);
  }

  toggleSelected(event) {
    if (event.target.nextElementSibling.classList.contains("selected")) {
      event.target.nextElementSibling.classList.remove("selected");
      if (
        event.target.nextElementSibling.innerText == "Otro" &&
        !event.target.classList.contains("normal-checkbox")
      ) {
        var input =
          event.target.nextElementSibling.parentNode.querySelector(".other");
        input.classList.add("hidden");
        input.firstElementChild.firstElementChild.value = "";
      }
    } else {
      event.target.nextElementSibling.classList.add("selected");
      this.toggleBenefits(event.target.nextElementSibling);
    }
  }

  toggleBenefits(element) {
    if (
      element.innerText == "Otro" &&
      !element.classList.contains("normal-checkbox")
    ) {
      var input = element.parentNode.querySelector(".other.hidden");
      input.classList.remove("hidden");
    }
  }
  // corregir
  selectOnConnect(targets) {
    const controller = this;
    targets.forEach(function (elem) {
      if (elem.checked) {
        elem.nextElementSibling.classList.add("selected");
        controller.toggleBenefits(elem.nextElementSibling);
      }
    });
  }
}
