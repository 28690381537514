import { stringify } from "postcss"
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    input: String,
  }

  colorize(e) {
    if (e.target.value == "") {
      e.target.classList.remove('selected')
    }
    else {
      e.target.classList.add('selected')
    }
  }

  cancel_filter() {
    let inputs = document.querySelectorAll(`[data-name^=${this.inputValue}]`)
    let button = document.getElementById('button-search-filter-workshop')
    inputs.forEach(input => input.checked = false)
    button.click()
  }
}
