import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  submit_create(event) {
    gtag("event", "Social_events", {
      event_category: "New",
      event_label: "New Social event form submitted",
      event_value: event.target[`musician_event[name]`].value,
    });
  }

  submit_edit(event) {
    gtag("event", "Social_events", {
      event_category: "Edit",
      event_label: "Edit social event form submitted",
      event_value: event.target.dataset.id,
    });
  }

  submit_apply(event) {
    gtag("event", "Social_events", {
      event_category: "Apply",
      event_label: "Apply to social event submitted",
      event_value: event.target.dataset.id,
    });
  }

  submit_commentary(event) {
    gtag("event", "Social_events", {
      event_category: "Comment",
      event_label: "Commentary submitted",
      event_value: event.target.dataset.id,
    });
  }
}
