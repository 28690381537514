window.dataLayer = window.dataLayer || [];
export default function gtag() {
  dataLayer.push(arguments);
}

gtag("js", new Date());

document.addEventListener("turbolinks:load", (event) => {
  const measurement_id =
    document.getElementById("gtag_script").dataset.measurementId;
  console.info(measurement_id);
  gtag("config", measurement_id, {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: document.title,
  });
});
