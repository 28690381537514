import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';
global.$ = jQuery;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "stylesheets/application"
import "images"

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
require("jquery")
require("@nathanvda/cocoon")


const application = Application.start()
const context = require.context('controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr)

window.initMap = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

import ViewportMobile from './viewport_mobile'

ViewportMobile.start()
