import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['icon']

  disconnect() {
    const dropdown = document.querySelector(this.dropdownSelector)

    if (dropdown) {
      dropdown.remove()
      return
    }
  }

  async toggle() {
    const dropdown = document.querySelector(this.dropdownSelector)

    if (dropdown) {
      dropdown.remove()
      this.iconTarget.style.transform = 'rotate(0deg)'
      return
    }

    this.element.insertAdjacentHTML('afterend', this.template)
    this.iconTarget.style.transform = 'rotate(180deg)'

    const response  = await fetch(this.element.dataset.url)
    const body      = await response.text()

    document.querySelector(this.containerSelector).innerHTML = body;
  }

  get containerSelector() {
    return `${this.dropdownSelector} [data-behavior=container]`;
  }

  get dropdownSelector() {
    return `[data-owner-id=${this.element.id}]`;
  }

  get template() {
    return `
      <tr data-owner-id='${this.element.id}'>
        <td data-behavior='container' colspan='100%'>
          <div class='text-center w-full'>
            <i class="fa fa-circle-notch fa-spin fa-2x"></i>
          </div>
        </td>
      </tr>
    `
  }
}


