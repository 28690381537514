import ScrollTo from 'stimulus-scroll-to'

export default class extends ScrollTo {
  connect() {
    super.connect()

    if (window.location.href.includes('#register')) {
      this.scrollToAnchor('#register');
    }

    if (window.location.href.includes('#plans')) {
      const plans = document.getElementById("plans").offsetTop;
      window.scrollTo({top: plans});
    }
  }

  // You can set default options in this getter for all your anchors.
  get defaultOptions() {
    return {
      offset: 100,
      behavior: 'auto',
    }
  }
}
