import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "photo",
    "circlePhoto",
    "inputPhoto",
    "inputCachePhoto",
    "inputCroppedDuplicate"
  ];

  static values = { url: String }

  connect() {
    // let target = this.inputCroppedDuplicateTarget
    // fetch(this.urlValue).then(data => data.blob()).then(
    //   blob => {
    //   debugger
    //   let filename = this.urlValue.substring(this.urlValue.lastIndexOf('/')+1)
    //   let file = new File([blob], filename, { type: `image/${this.urlValue.substring(this.urlValue.lastIndexOf('.')+1)}` });
    //   let container = new DataTransfer();
    //   container.items.add(file);
    //   target.files = container.files;
    //  }
    // )
  }

  showImage(event) {
    var file = event.target.files[0];
    var image_container = event.target
      .closest(".nested-fields")
      .querySelector(".image-field .image-container");
    var card = event.target
      .closest(".nested-fields")
      .querySelector(".image-field .image-input-card");
    var img = event.target
      .closest(".nested-fields")
      .querySelector(".image-field img");
    if (file) {
      var fr = new FileReader();
      fr.onload = function () {
        card.hidden = true;
        image_container.hidden = false;
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  }

  showImagePhoto(event) {
    var file = event.target.files[0];
    const cropperElement = event.target.closest("#cropper");

    if (file) {
      var fr = new FileReader();
      var circle = this.circlePhotoTarget;

      if ($(".svg-profile-photo").length > 0)
        var svg = $(".svg-profile-photo")[0];

      fr.onload = function () {
        if (typeof svg !== "undefined") svg.hidden = true;
        circle.hidden = false;
        const cropper = cropperElement.cropper;
        let cropperModal = cropperElement.querySelector("#cropper-modal");
        let cropperModalImg = cropperModal.querySelector("img");
        cropperModal.style.display = "flex";
        cropperModalImg.src = fr.result;
        cropper.rebuild({ ratio: 1, rounded: true });
      };
      fr.readAsDataURL(file);
    }
  }

  addProfilePhoto(event) {
    this.inputPhotoTarget.click();
  }

  removePhotoPicture(event) {
    event.stopPropagation();
    this.inputPhotoTarget.value = null;
    this.inputCachePhotoTarget.value = null;
    this.PhotoTarget.src = "";
  }
}
