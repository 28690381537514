import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tooltip"];
  toggle(event) {
    event.preventDefault();
    let targetId = event.currentTarget.getAttribute("data-toggle-register-tooltips-target");
    let target = document.getElementById(targetId);
    const paddingSectionContainer = Number(window.getComputedStyle(target.closest('section')).padding.replace('px', ''))
    const width = Number(window.getComputedStyle(target.closest('section')).width.replace('px', '')) - paddingSectionContainer * 2

    if (target) {
      this.tooltipTargets.forEach((tooltip) => {
        if (target != tooltip && !tooltip.classList.contains("hidden")) {
          tooltip.classList.toggle("hidden");
        }
      });
      target.style.width = `${width}px`;
      target.classList.toggle("hidden");
    }
  }
}