import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input']

  static values = {
    inputMobile: String,
    inputDesktop: String
  }

  desktopInputClicked() {
    let mobileInput = document.querySelectorAll(`[data-name=${this.inputMobileValue}]`)[0]
    mobileInput.checked = this.inputTarget.checked
  }

  mobileInputClicked() {
    let desktopInput = document.querySelectorAll(`[data-name=${this.inputDesktopValue}]`)[0]
    desktopInput.checked = this.inputTarget.checked
  }
}