import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  static values = {
    inputMobile: String,
    inputDesktop: String,
  };

  desktopInputClicked() {
    let desktopInput = document.querySelectorAll(
      `[data-name=${this.inputDesktopValue}]`
    )[0];
    desktopInput.checked = this.inputTarget.checked;
  }

  mobileInputClicked() {
    let mobileInput = document.querySelectorAll(
      `[data-name=${this.inputMobileValue}]`
    )[0];
    mobileInput.checked = this.inputTarget.checked;
  }

  search_locality() {
    let valueProvince = document.getElementById("filter_province").value;
    let locality = document.getElementById("musical-project-locality");
    let loadLocality = document.getElementById("filter_locality");

    $.ajax({
      url: "project_finder/load_localities",
      data: {
        selected_province: valueProvince,
      },
      success: (result) => {
        loadLocality.replaceChildren();
        let optionBlank = document.createElement("option");
        optionBlank.value = "";
        optionBlank.text = "";
        let tmp = result.map((element) => {
          let option = document.createElement("option");
          option.value = element;
          option.text = element;
          return option;
        });
        loadLocality.append(optionBlank, ...tmp);
      },
    });

    locality.classList.remove("hidden");
  }

  areAnyCheckboxesChecked() {
    const provinceCheckboxes = document
      .getElementById("province-dropdown")
      .querySelectorAll(
        '[data-controller="project-finder-filter-update"] input[type="checkbox"]'
      );

    for (const checkbox of provinceCheckboxes) {
      if (checkbox.checked) {
        return true; // Hay al menos un checkbox marcado
      }
    }

    return false; // No hay checkboxes marcados
  }

  updateLocalityDropdown(event) {
    const selectedProvince = event.target.value; // Obtén el valor seleccionado en el dropdown de provincia
    const is_checked = event.target.checked;

    const localityCheckboxes = document
      .getElementById("locality-dropdown")
      .querySelectorAll('[data-controller="project-finder-filter-update"]'); // Obtén los divs con los checkboxes

    fetch(
      `/project_finder/load_localities?selected_province=${selectedProvince}`
    )
      .then((response) => response.json())
      .then((data) => {
        const localities = data.map((locality) => locality.toLowerCase()); // Asumiendo que las localidades están en minúsculas

        const areAnyCheckboxesChecked = this.areAnyCheckboxesChecked();

        if (areAnyCheckboxesChecked === false) {
          // Si no hay opciones en data, muestra todos los checkboxes
          localityCheckboxes.forEach((checkbox) => {
            checkbox.style.display = ""; // O ajusta el estilo para mostrarlo
          });
        } else {
          // Itera sobre los checkboxes y ajusta el estilo en función de is_checked
          localityCheckboxes.forEach((checkbox) => {
            const label = checkbox.querySelector("label");
            const labelText = label.textContent.trim().toLowerCase(); // Obtén el texto del label en minúsculas

            if (!localities.includes(labelText)) {
              if (checkbox.style.display !== "block") {
                checkbox.style.display = "none"; // Cambia el estilo a "none" si no es "block"
              }
            } else {
              if (is_checked === true) {
                checkbox.style.display = "block"; // O ajusta el estilo para mostrarlo
              } else {
                checkbox.style.display = "none"; // Cambia el estilo a "none" si no es "block"
              }
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error al obtener las localidades:", error);
      });
  }

  areAnyCheckboxesCheckedMobile() {
    const provinceCheckboxesMobile = document
      .getElementById("province-dropdown-mobile")
      .querySelectorAll(
        '[data-controller="project-finder-filter-update"] input[type="checkbox"]'
      );

    for (const checkbox of provinceCheckboxesMobile) {
      if (checkbox.checked) {
        return true; // Hay al menos un checkbox marcado
      }
    }

    return false; // No hay checkboxes marcados
  }

  updateLocalityDropdownMobile(event) {
    const selectedProvince = event.target.value; // Obtén el valor seleccionado en el dropdown de provincia
    const is_checked = event.target.checked;

    const localityCheckboxesMobile = document
      .getElementById("locality-dropdown-mobile")
      .querySelectorAll('[data-controller="project-finder-filter-update"]'); // Obtén los divs con los checkboxes
    // Hacemos la solicitud AJAX para obtener las localidades basadas en la provincia seleccionada

    fetch(
      `/project_finder/load_localities?selected_province=${selectedProvince}`
    )
      .then((response) => response.json())
      .then((data) => {
        const localities = data.map((locality) => locality.toLowerCase()); // Asumiendo que las localidades están en minúsculas

        const areAnyCheckboxesChecked = this.areAnyCheckboxesCheckedMobile();

        if (areAnyCheckboxesChecked === false) {
          // Si no hay opciones en data, muestra todos los checkboxes
          localityCheckboxesMobile.forEach((checkbox) => {
            checkbox.style.display = ""; // O ajusta el estilo para mostrarlo
          });
        } else {
          // Itera sobre los checkboxes y oculta aquellos que no coincidan con las localidades
          localityCheckboxesMobile.forEach((checkbox) => {
            const label = checkbox.querySelector("label");
            const labelText = label.textContent.trim().toLowerCase(); // Obtén el texto del label en minúsculas
            if (!localities.includes(labelText)) {
              if (checkbox.style.display !== "block") {
                checkbox.style.display = "none"; // Cambia el estilo a "none" si no es "block"
              }
            } else {
              if (is_checked === true) {
                checkbox.style.display = "block"; // O ajusta el estilo para mostrarlo
              } else {
                checkbox.style.display = "none"; // Cambia el estilo a "none" si no es "block"
              }
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error al obtener las localidades:", error);
      });
  }
}
