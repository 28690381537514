import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  connect() {
    document.addEventListener("turbolinks:load", () => {
      const { track, id, url } = this.element.dataset;
      if (track === 'true') {
        // This user is not yet tracked.
        gtag("event", "Confirmation", {
          event_category: `Account Confirmation`,
          event_label: `Account Confirmation Succceded`,
          event_value: id,
        });
        // Now set this user as tracked.
        fetch(url);
      }
    });
  }
}
