import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  submit_create(event) {
    gtag("event", "Venues", {
      event_category: `New`,
      event_label: `New Venue form submitted`,
      event_value: event.target[`venue[name]`].value,
    });
  }

  submit_edit(event) {
    gtag("event", "Venues", {
      event_category: "Edit",
      event_label: "Edit Venue form submitted",
      event_value: event.target.dataset.id,
    });
  }

  delete(event) {
    gtag("event", "Venues", {
      event_category: "Delete",
      event_label: "Venue deleted",
      event_value: event.target.dataset.id,
    });
  }
}
