import BaseImageInputController from "./base_image_input_controller";

export default class extends BaseImageInputController {
  static targets = [
    "input",
    "photo",
    "circlePhoto",
    "inputPhoto",
    "inputCachePhoto",
  ];

  showImage(event) {
    var file = event.target.files[0];
    var image_container = event.target
      .closest(".nested-fields")
      .querySelector(".image-field .image-container");
    var card = event.target
      .closest(".nested-fields")
      .querySelector(".image-field .image-input-card");
    var img = event.target
      .closest(".nested-fields")
      .querySelector(".image-field img");
    if (file) {
      var fr = new FileReader();
      fr.onload = function () {
        card.hidden = true;
        image_container.hidden = false;
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  }

  showImagePhoto(event) {
    var file = event.target.files[0];
    const cropperElement = event.target.closest("#cropper");

    if (file && this.inWhitelist(file.type)) {
      this.removeImgTypeError(cropperElement);
      var fr = new FileReader();
      var circle = this.circlePhotoTarget;

      if ($(".svg-profile-photo").length > 0)
        var svg = $(".svg-profile-photo")[0];

      fr.onload = function () {
        if (typeof svg !== "undefined") svg.hidden = true;
        circle.hidden = false;
        const cropper = cropperElement.cropper;
        let cropperModal = cropperElement.querySelector("#cropper-modal");
        let cropperModalImg = cropperModal.querySelector("img");
        cropperModal.style.display = "flex";
        cropperModalImg.src = fr.result;
        cropper.rebuild({ ratio: 1, rounded: true });
      };
      fr.readAsDataURL(file);
    } else {
      this.appendImgTypeError(cropperElement);
      this.removePhotoPicture(event);
    }
  }

  addProfilePhoto(event) {
    this.inputPhotoTarget.click();
  }

  removePhotoPicture(event) {
    event.stopPropagation();
    this.inputPhotoTarget.value = null;
    this.inputCachePhotoTarget.value = null;
    this.PhotoTarget.src = "";
  }
}
