import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  pending(event) {
    gtag("event", "Postulations", {
      event_category: "Pending",
      event_label: "Pending Postulation",
      event_value: event.target.dataset.id,
    });
  }

  accept(event) {
    gtag("event", "Postulations", {
      event_category: "Accepted",
      event_label: "Postulation accepted",
      event_value: event.target.closest("div.btn").dataset.id,
    });
  }

  reject(event) {
    gtag("event", "PoIIstulations", {
      event_category: "Rejected",
      event_label: "Postulation rejected",
      event_value: event.target.closest("div.btn").dataset.id,
    });
  }
}
