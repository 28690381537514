import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    check: Boolean,
    id: Number
  }

  connect() {
    let elem = document.getElementById('customSwitchDiscount')
    elem.checked = this.checkValue;
  }
  submitForm() {
    document.getElementById('submitUpdateDiscount').click();
    document.querySelectorAll(`.disabledChip${this.idValue}`).forEach(chip => {
      chip.hidden = !chip.hidden
    })
    document.querySelectorAll(`.enabledChip${this.idValue}`).forEach(chip => {
      chip.hidden = !chip.hidden
    })
  }
}
