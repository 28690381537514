import { Controller } from "stimulus"

export default class extends Controller {
  show(){
    // Tomamos las dos cards, la que contiene el texto y la que contiene a la card

    var card = document.getElementById('card-workshop')
    var text = document.getElementById('text-how-it-works')

    // Cuando ocultamos el texto le tenemos que agregar el borde a la card
    // Cuando lo mostramos se lo sacamos

    var text = document.getElementById('text-how-it-works');
    if (text.classList.contains('h-hide')) {
      card.classList.remove('rounded-b-xl');
      text.classList.remove('h-hide');

    } else {
      card.classList.add('rounded-b-xl');
      text.classList.add('h-hide');
    }

  }
}
