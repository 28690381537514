import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    buttonContent: String
  }
  static targets = ['button', 'modal', 'confirmationModal']

  connect() {
    this.addCloseModalEventListeners()
  }

  async submitRequest(event) {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    return fetch(this.urlValue, {
      method: 'POST',
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.ok) {
        document.querySelectorAll(".send-request-button").forEach(button => {
          button.classList.remove('request-service-button')
          button.classList.add('request-service-button-disabled')
          button.innerHTML = this.buttonContentValue
        })
        this.closeAllModals()
        this.openConfirmationModal()
      }
    })
  }

  toggleConfirmActionModal() {
    if (this.buttonTarget.classList.contains('request-service-button-disabled')) return
    this.modalTarget.classList.toggle('hidden')
    document.body.classList.toggle('modal-active')
  }

  openConfirmationModal() {
    this.confirmationModalTarget.classList.toggle('hidden')
    document.body.classList.toggle('modal-active')
  }

  closeAllModals() {
    document.querySelectorAll(".modal").forEach(modal => {
      modal.classList.add('hidden')
      document.body.classList.remove('modal-active')
    })
  }

  addCloseModalEventListeners() {
    let controller = this

    document.onkeydown = function (e) {
      if (e.key === "Escape" && document.body.classList.contains('modal-active')) {
        controller.closeAllModals()
      }
    }
  }
}