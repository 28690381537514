import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
  connect() {
    this.initializeSlideHome()
  }

  initializeSlideHome() {
    new Splide(this.element, {
      classes: {
        arrow: "splide__arrow home-event-arrows",
        prev: "splide__arrow--prev home-event-prev",
        next: "splide__arrow--next home-event-next",
      },
      rewind: true,
      pagination: true,
      cover: true,
      type: "slide",
      arrows: true,
      perMove: 1,
    }).mount();
  }
}
