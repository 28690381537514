import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];

  connect() {
    const serchparams = new URLSearchParams(window.location.search)
    const index =  parseInt(serchparams.get("viewBanner")) - 1 || 0 
    const Tab = this.tabTargets[index];
    if (Tab) {
      Tab.click();
    }
  }

  changeTab(event) {
    event.preventDefault();

    const targetTab = event.currentTarget;
    const targetName = targetTab.dataset.carouselSlide;

    this.tabTargets.forEach((tab) => {
      const isActive = tab.dataset.carouselSlide === targetName;
      tab.classList.toggle("active", isActive);
    });

    this.contentTargets.forEach((content) => {
      const isActive = content.dataset.carouselSlide === targetName;
      content.classList.toggle("active", isActive);
    });

    this.tabTargets.forEach((tab) => {
      if (tab.classList.contains("tab-pane") && tab.classList.contains("active")){
        tab.children[0].children[0].children[0].children[0].click();
      }
    })

    this.tabTargets.forEach((tab) => {
      const tabName = tab.dataset.carouselSlide;
      const textElement = tab.querySelector(`[data-carousel-slide="${tabName}"]`);
      if (textElement) {
        textElement.classList.toggle("pink-text", tab.classList.contains("active"));
      }
    });
  }
}