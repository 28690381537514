import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String }

  changeEmail(){
    var controller = this
    fetch(this.urlValue)
    .then((response) => response.text())
    .then((content) => {
      let message = document.getElementById('message-change-email')
      message.classList.remove('hidden')
      //$('#message-email-delete').classList.remove('hidden')
      $('#message-change-email').html(content)
      controller.toggleModal();
    });
  }

  toggleModal(){
    const body = document.querySelector('body')
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
  }
}
