import { Controller } from "stimulus"
import { Spanish } from "flatpickr/dist/l10n/es.js"
export default class extends Controller {

  static targets = [
    'inputDays',
    'divDateRange',
    'inputFrequency',
    'daysSelector'
    ]

  connect(){
    window.onload = this.setUpPreviousCalendar()
  }

  initializeFlatpickr(){
    this.flatpickrDaysTarget = flatpickr(this.inputDaysTarget,
      {    
        "locale": Spanish,
        disableMobile: true,
        dateFormat: "d-m-Y",
        minDate: "today"
      }
    );
  }

	toggleSelected(event) {
    switch (event.target.value) {
      case "unique":
        this.divDateRangeTarget.firstElementChild.innerHTML = "Días";
        this.flatpickrDaysTarget.clear();
        this.flatpickrDaysTarget.config.mode = "multiple";
        this.daysSelectorTarget.classList.add('hidden');
        this.divDateRangeTarget.classList.remove('hidden');     
        break;
      case "daily":
        this.divDateRangeTarget.firstElementChild.innerHTML = "Días";
        this.flatpickrDaysTarget.clear();
        this.flatpickrDaysTarget.config.mode = "range";
        this.daysSelectorTarget.classList.add('hidden');
        this.divDateRangeTarget.classList.remove('hidden');
        break;
      case "weekly":
        this.divDateRangeTarget.firstElementChild.innerHTML = "Desde/Hasta";
        this.flatpickrDaysTarget.clear();
        this.flatpickrDaysTarget.config.mode = "range";
        this.daysSelectorTarget.classList.remove('hidden');
        this.divDateRangeTarget.classList.remove('hidden');
        break;
    } 
  }

  setUpPreviousCalendar(){
    let lastValue = this.inputDaysTarget.value
    if (this.inputFrequencyTarget.value){
      switch (this.inputFrequencyTarget.value) {
        case "unique":
          this.flatpickrDaysTarget = this.initFlatpickr(this.inputDaysTarget, 'multiple', lastValue)
          this.divDateRangeTarget.classList.remove('hidden');
          this.daysSelectorTarget.classList.add('hidden');
          break;
        case "daily":
          this.flatpickrDaysTarget = this.initFlatpickr(this.inputDaysTarget, 'range', lastValue)
          this.divDateRangeTarget.classList.remove('hidden');
          this.daysSelectorTarget.classList.add('hidden');
          break;
        case "weekly":
          this.divDateRangeTarget.firstElementChild.innerHTML = "Desde/Hasta";
          this.flatpickrDaysTarget = this.initFlatpickr(this.inputDaysTarget, 'range', lastValue) 
          this.divDateRangeTarget.classList.remove('hidden');
          this.daysSelectorTarget.classList.remove('hidden');
          break;
      } 
    } else {
      this.initializeFlatpickr()
    }
  }

  formatDefaultValue(mode, value){
    switch (mode) {
      case "range":
        return value.split(' a ')
      case "multiple":
        return value.split(',')
      default:
        return value
    }
  }

  initFlatpickr(element, mode='multiple', value){
    let controller = this
    return flatpickr(
      element,
      {
        "locale": Spanish,
        disableMobile: true,
        dateFormat: "d-m-Y",
        minDate: "today",
        mode: mode,
        defaultDate: controller.formatDefaultValue(mode, value)
      }
    );
  }
}
