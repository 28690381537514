import { stringify } from "postcss"
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    input: String,
  }

  colorize(e){
    if (e.target.value == "") {
      e.target.classList.remove('selected')
    }
    else {
      e.target.classList.add('selected')
    }
  }

  cancel_filter(){    
    var input =  document.getElementById(this.inputValue)
    var button =  document.getElementById('button-search-filter')
    input.checked = false
    button.click();
  }
}