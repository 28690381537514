import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { 
    currentUser: String
  }

  connect() {
    this.modalListener();
  }

  freemium() {
    if (this.currentUserValue !== 'true') {
      event.preventDefault();
      this.toggleModal();
    }
  }

  toggleModal() {
    const body = document.querySelector('body')
    const modalContainer = document.getElementsByClassName('hidden-modal')[0];
    const modal = document.querySelector('.freemium-modal')
    body.classList.add('modal-active')
    modalContainer.classList.remove('hidden');
    modal.classList.toggle('opacity-0')
    modal.classList.remove('pointer-events-none')
  }

  closeModal() {
    const body = document.querySelector('body')
    const modalContainer = document.getElementsByClassName('hidden-modal')[0];
    const modal = document.querySelector('.freemium-modal')
    body.classList.remove('modal-active')
    modalContainer.classList.add('hidden');
    modal.classList.toggle('opacity-0')
    modal.classList.add('pointer-events-none')
  }

  modalListener() {
    document.addEventListener('keydown', (event) => {
      let modalContainer = document.getElementsByClassName('hidden-modal')[0];
      if (event.key === 'Escape' && (!modalContainer.classList.contains('hidden') || modalContainer.classList.contains('modal-active'))) {
        this.closeModal();
      }
    });
  }
}