import { Controller } from "stimulus"

export default class extends Controller {
  static values = { scroll: String }

  static targets = ['modal', 'form', 'billingTable', 'dateInput', 'dateInputError', 'fileInput', 'fileInputLabel', 'fileInputError', 'submitButton']

  initialize() {
    this.boundOnKeyDown = this.onKeyDown.bind(this)
  }

  connect() {
    if (this.scrollValue) this.billingTableTarget.scrollIntoView({ block: 'center', behavior: 'smooth' })
    window.addEventListener("keydown", this.boundOnKeyDown)
  }

  dateInputChanged() {
    this.toggleDateError()
  }

  fileInputChanged() {
    this.fileInputLabelTarget.innerHTML = this.fileInputTarget.files[0].name
    this.toggleFileError()
  }

  inputsChanged() {
    this.submitButtonTarget.disabled = !(this.dateInputValid() && this.fileInputValid())
  }

  dateInputValid() {
    return this.dateInputTarget.value
  }

  fileInputValid() {
    return this.fileInputTarget.value && this.fileInputTarget.files[0].type == 'application/pdf'
  }

  toggleDateError() {
    this.dateInputErrorTarget.hidden = this.dateInputValid()
  }

  toggleFileError() {
    this.fileInputErrorTarget.hidden = this.fileInputValid()
  }

  submitForm(event) {
    event.preventDefault()
    if (this.dateInputValid() && this.fileInputValid()) {
      this.formTarget.submit()
    } else {
      this.toggleDateError()
      this.toggleFileError()
    }
  }

  toggleSubscriptionBillingModal() {
    this.modalTarget.toggleAttribute('hidden')
  }

  onKeyDown(event) {
    if (event.keyCode === 27) {
      if (!this.modalTarget.hasAttribute('hidden')) this.toggleSubscriptionBillingModal()
    }
  }
}
