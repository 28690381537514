// app/javascript/controllers/banner_form_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form_edit'];

  editBanner(event) {
    let formEdit = this.form_editTarget
    formEdit.classList.remove("d-none")
  }
}
