import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    currentUser: String
  }

  request() {
    const chatbox    = document.getElementById('chatbox');
    const controller = this.application.getControllerForElementAndIdentifier(chatbox, 'chat-popup');
    if (this.currentUserValue === 'true') {
      controller.loadChatBox(this.urlValue);
    } else {
      const modalController = this.application.getControllerForElementAndIdentifier(document.getElementById("alert"),"freemium");
      modalController.freemium();
    }
  }
}
