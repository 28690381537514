import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
  static values = {
    dates: Number
  }

  connect(){ 
    this.initializeSlideSocialEvent()
  }


  initializeSlideSocialEvent(){
    // los width breakpoints estan hechos para que se vean solo 3 cards
    // en el carrusel sin que se corten, se debe poder mejorar y hacerlo
    // más flexible que pixeles fijos
    const arrow = this.datesValue > 3;
    new Splide(".splide-social-event-show-dates", {
      classes: {
        arrow: "splide__arrow social-event-arrows",
        prev: "splide__arrow--prev social-event-prev",
        next: "splide__arrow--next social-event-next",
      },
      perPage: 2,
      perMove: 1,
      rewind: true,
      autoWidth: true,
      pagination: false,
      arrows: arrow,
      gap: ".5rem",
      width: "670px",
      breakpoints: {
        1536: {
          width: "590px",
          gap: "1rem",
        },
      },
    }).mount();
  }
}