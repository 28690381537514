import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['requestSections', 'dropdownArrow']

  toggleAccordion() {
    let height = this.requestSectionsTarget.scrollHeight
    this.dropdownArrowTarget.classList.toggle('rotate-180')
    if (this.isAccordionOpen()) {
      this.requestSectionsTarget.classList.remove('max-h-auto')
      this.requestSectionsTarget.style.maxHeight = 0 + "px"
    }
    else {
      this.requestSectionsTarget.style.maxHeight = height + "px"
    }
  }

  isAccordionOpen() {
    let maxHeight = this.requestSectionsTarget.style.maxHeight
    return this.requestSectionsTarget.classList.contains('max-h-auto') || (maxHeight.length != 0 && maxHeight != '0px')
  }
}