import  Flatpickr from "stimulus-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"

export default class extends Flatpickr {

  initialize(){
    this.config = {
      locale: Spanish
    }
  }

}