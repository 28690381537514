import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
  connect(){ 
    this.initializeSlideGenre()
  }

  initializeSlideGenre(){
    new Splide( '.splide-genres', {
      rewind: false,
      autoWidth: true, // Se ajusta al ancho del elemento automáticamente
      pagination: false,
      arrows: false,      
    } ).mount();
  }
}