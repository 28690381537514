import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  connect() {
    document.addEventListener("turbolinks:load", () => {
      gtag("event", "Sign_up", {
        event_category: "Render",
        event_label: "Form rendered",
        non_interaction: true,
      });
    });
  }

  submit_sign_up(event) {
    gtag("event", "Sign_up", {
      event_category: "E-Mail",
      event_label: "Sign Up Form submitted",
      event_value: event.target["user[email]"].value,
    });
  }

  submit_sign_up_facebook() {
    gtag("event", "Sign_up", {
      event_category: "Facebook",
      event_label: "Sign Up with Facebook",
      event_value: "Facebook",
    });
  }

  submit_sign_up_google() {
    gtag("event", "Sign_up", {
      event_category: "Google",
      event_label: "Sign Up with Google",
      event_value: "Google",
    });
  }
}
