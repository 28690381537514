import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input']

  static values = {
    inputMobile: String,
    inputDesktop: String
  }

  desktopInputClicked() {
    let mobileInput = document.querySelectorAll(`[data-name=${this.inputMobileValue}]`)[0]
    mobileInput.checked = this.inputTarget.checked
  }

  mobileInputClicked() {
    let desktopInput = document.querySelectorAll(`[data-name=${this.inputDesktopValue}]`)[0]
    desktopInput.checked = this.inputTarget.checked
  }

  search_locality() {
    
    let valueProvince = document.getElementById('filter_province').value
    let locality = document.getElementById('musical-project-locality')
    if ( valueProvince != 'Tienda Online'){
      let loadLocality = document.getElementById('filter_locality')
      $.ajax({
        url: 'discount_finder/load_localities',
        data: {
          'selected_province': valueProvince
        },
        success:  (result) => {
          loadLocality.replaceChildren()
          let optionBlank = document.createElement('option')
              optionBlank.value = ''
              optionBlank.text = ''
          let tmp = result.map(element => {
            let option = document.createElement('option')
            option.value = element
            option.text = element
            return option
          });
          loadLocality.append(optionBlank,...tmp)
        }
      })
      locality.classList.remove('hidden')
    }
    else {
      locality.classList.add('hidden')
    }
  }
}