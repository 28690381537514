import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['date', 'status', 'optionRecieved', 'optionSent', 'showOptionSelected', 'showOptionsContainer', 'dateOptionSelected', 'dateOptionsContainer']

  connect(){
    this.selectOnConnect(this.statusTargets);
    this.handleShowDay();
  }

  handleShowDay(){
    let selectedShow = this.showOptionsContainerTarget.querySelector(".radio[checked]");
    let selectedDate = this.dateOptionSelectedTarget.getAttribute('data-option-selected');

    if (selectedShow) {
      $("#date").prop('disabled', false)

      $.ajax({
        url: 'postulations/load_show_days',
        data: {
          'selected_social_event': selectedShow.value,
          'selected_date': selectedDate
        },
        success:  (result) => {
          $("#selectDate").html(result).removeClass("active");
          $("#date").prop('checked', false);
        }
      })
    }
  }

  toggleActiveLink() {
    let receive = $('#recibir');
    let send = $("#enviar");

    this.optionRecievedTarget.addEventListener("click", (event) => {
      send.removeClass("link-active");
      receive.addClass("link-active");
    });

    this.optionSentTarget.addEventListener("click", (event) => {
      receive.removeClass("link-active");
      send.addClass("link-active");
    });
  }

  addEventClick(){
    $("#submit").trigger('click');
  }

  selectOnConnect(targets){
    targets.forEach(function(elem){
      if (elem.checked){
        elem.nextElementSibling.classList.remove('btn-postulations')
        elem.nextElementSibling.classList.add('btn-postulations-selected')
      }
    });
  }

  selectShowOption(event) {
    const input = event.currentTarget;
    const dateIntput = this.dateOptionsContainerTarget.querySelector(".radio[checked]");

    input.querySelector("input").checked = true; //Selecciona el radio button cuando se clickea el div
    this.showOptionSelectedTarget.innerHTML = input.querySelector("label").innerHTML;
    this.showOptionsContainerTarget.classList.remove("active");


    if (dateIntput) dateIntput.checked = false;

    this.addEventClick();
  }

  selectDateOption(event) {
    let input = event.currentTarget;

    input.querySelector("input").checked = true; //Selecciona el radio button cuando se clickea el div
    this.dateOptionSelectedTarget.innerHTML = input.querySelector("label").innerHTML;
    this.dateOptionsContainerTarget.classList.remove("active");

    this.addEventClick();
  }

  toggleShowSelect(event) {
    this.showOptionsContainerTarget.classList.toggle("active");
    event.stopPropagation(); //Close custom select on click outside
  }

  toggleDateSelect(event) {
    this.dateOptionsContainerTarget.classList.toggle("active");
    event.stopPropagation(); //Close custom select on click outside
  }

}
