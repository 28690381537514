import { Controller } from "stimulus";

export default class extends Controller {
  inWhitelist(type) {
    return ["image/jpg", "image/jpeg", "image/png", "image/webp"].includes(
      type
    );
  }

  appendImgTypeError(cropper) {
    cropper.insertAdjacentHTML(
      "beforeend",
      `<div id="image-type-err" class="help-block text-small text-error text-center">El archivo que intenta subir no está permitido.</div>`
    );
  }

  removeImgTypeError(cropper) {
    cropper.querySelector("#image-type-err")?.remove();
  }
}
