import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String
  }
  static targets = [ "output", "input" ]

  render_social_event(params){
    var filter = "?filter=" + params
    fetch(this.urlValue + filter)
    .then((response) => response.text())
    .then((content) => {
      $('#socialEventDate').html(content)
    })
  }

  connect(){
    this.handleInput()
  }

  handleInput(){
    var input = this.inputTarget
    var controller = this
    this.inputTarget.addEventListener('change', (event) => {
      if (input.checked) {
        controller.render_social_event('host')
      } else {
        controller.render_social_event('all-next')
      }
    })
  }
}
