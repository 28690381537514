import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    toggleActiveUrl: String,
    deleteServiceUrl: String,
  }
  static targets = ['toggle', 'modal']

  connect() {
    this.addCloseModalEventListeners()
  }

  async toggleActive(event) {
    event.preventDefault()
    const csrfToken = document.querySelector("[name='csrf-token']").content
    return fetch(this.toggleActiveUrlValue, {
      method: 'PATCH',
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.ok) {
        this.toggleTargets.forEach(toggle => toggle.checked = !toggle.checked)
      }
    })
  }

  async deleteService(event) {
    let servicesContainer = document.getElementById('services-container')
    let noServicesContainer = document.getElementById('no-services-container')
    let main_add_service_button = document.getElementById('main_add_service_button')
    const csrfToken = document.querySelector("[name='csrf-token']").content
    return fetch(this.deleteServiceUrlValue, {
      method: 'DELETE',
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.ok) {
        this.element.remove()
        if (servicesContainer.childElementCount == 0) {
          main_add_service_button.classList.add('hidden')
          noServicesContainer.classList.remove('hidden')
        }
        this.toggleConfirmDeleteModal()
      }
    })
  }

  toggleConfirmDeleteModal() {
    document.querySelectorAll("[id^='options_menu_checkbox']").forEach(checkbox => checkbox.checked = false)
    this.modalTarget.classList.toggle('hidden')
    document.body.classList.toggle('modal-active')
  }

  closeConfirmDeleteModal() {
    document.querySelectorAll(".modal").forEach(modal => {
      modal.classList.add('hidden')
      document.body.classList.remove('modal-active')
    })
  }

  addCloseModalEventListeners() {
    let controller = this

    document.onkeydown = function (e) {
      if (e.key === "Escape" && document.body.classList.contains('modal-active')) {
        controller.closeConfirmDeleteModal()
      }
    }
  }
}