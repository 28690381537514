import { Controller } from "stimulus"

export default class extends Controller {
  connect(){ 
    this.handleCheck();
    this.scroollEvent();
  }

  handleCheck(){
    var header = document.getElementById("header");
    var navMenuDiv = document.getElementById("nav-content");
    var navMenu = document.getElementById("nav-toggle");

    document.onclick = check;
    function check(e) {
      var target = (e && e.target) || (event && event.srcElement);

      //Nav Menu
      if (!checkParent(target, navMenuDiv)) {
        // click NOT on the menu
        if (checkParent(target, navMenu)) {
          // click on the link
          if (navMenuDiv.classList.contains("hidden")) {
            navMenuDiv.classList.remove("hidden");
            header.classList.add("bg-primary-black");
            header.classList.add("shadow");
          } else {
            navMenuDiv.classList.add("hidden");
            header.classList.remove("bg-primary-black");
            header.classList.remove("shadow");
          }
        } else {
          // click both outside link and outside menu, hide menu
          navMenuDiv.classList.add("hidden");
        }
      }
    }
    function checkParent(t, elm) {
      while (t.parentNode) {
        if (t == elm) {
          return true;
        }
        t = t.parentNode;
      }
      return false;
    }
  }

  scroollEvent(){
    var scrollpos = window.scrollY;
    var header = document.getElementById("header");
    if (header == null) {
      var header = document.getElementById("header-fixed");    
    }
    var navcontent = document.getElementById("nav-content");
    var toToggle = document.querySelectorAll(".toggleColour");
  
    if (header.id == "header"){
      document.addEventListener("scroll", function () {
        /*Apply classes for slide in bar*/
        scrollpos = window.scrollY;
      
        if (scrollpos > 10) {
          header.classList.add("bg-primary-black");
          //Use to switch toggleColour colours
          header.classList.add("shadow");
        } else {
          if(navcontent.classList.contains('hidden')) {
            header.classList.remove("bg-primary-black");
            header.classList.remove("shadow");
          }
          //Use to switch toggleColour colours
          for (var i = 0; i < toToggle.length; i++) {
            toToggle[i].classList.add("text-primary-white");
            toToggle[i].classList.remove("text-primary-black");
          }
        }
      });
    }
  }
}