import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  submit_create(event) {
    const type = event.target.dataset.type;
    gtag("event", "Profiles", {
      event_category: `${type} - New`,
      event_label: `New ${type} Profile form submitted`,
      event_value:
        event.target[`${type.toLowerCase()}_profile[user_attributes][email]`].value,
    });
  }

  submit_edit(event) {
    gtag("event", "Profiles", {
      event_category: "Edit",
      event_label: "Edit Profile form submitted",
      event_value: event.target.dataset.type,
    });
  }
}
