import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {

  connect() {
    this.initializeSlideBenefits()
  }

  initializeSlideBenefits() {
    new Splide('.splide-benefits', {
      autoplay: true,
      rewind: true,
      type: "loop",
      arrows: true,
      perPage: 1,
      perMove: 1,
      breakpoints: {
        640: {
          arrows: false,
        },
      },
    }).mount();
  }
}