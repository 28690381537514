import { Controller } from "stimulus"

export default class extends Controller {
  static values = { 
    check: Boolean,
    id: Number 
  }

  connect(){
    let elem = document.getElementById('customSwitchBusiness')
    elem.checked = this.checkValue;
  }

  submitForm(){
    document.getElementById('submitUpdateBusiness').click();
    document.querySelectorAll(`.disabledChipBusiness${this.idValue}`).forEach(chip => {
      chip.hidden = !chip.hidden
    })
    document.querySelectorAll(`.enabledChipBusiness${this.idValue}`).forEach(chip => {
      chip.hidden = !chip.hidden
    })
  }
}