import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['modal']

  connect() {
    this.addCloseModalEventListeners()
  }

  toggleConfirmActionModal(event) {
    event.preventDefault()
    this.modalTarget.classList.toggle('hidden')
    document.body.classList.toggle('modal-active')
  }

  closeAllModals() {
    document.querySelectorAll(".modal").forEach(modal => {
      modal.classList.add('hidden')
      document.body.classList.remove('modal-active')
    })
  }

  submitActionForm() {
    this.element.submit()
  }

  addCloseModalEventListeners() {
    let controller = this

    document.onkeydown = function (e) {
      if (e.key === "Escape" && document.body.classList.contains('modal-active')) {
        controller.closeAllModals()
      }
    }
  }
}