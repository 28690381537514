import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "tooltip"];

  shotCheckboxErrMsg(show = false) {
    this.tooltipTarget.style.cssText = `opacity:${
      show ? "1" : "0"
    };z-index:${show ? "1" : "-1"}`;
  }

  onInvalid() {
    if (!this.checkboxTarget.checked) {
      document.getElementById("Checkbox").removeAttribute('required');
    } else {
      this.checkboxTarget.setCustomValidity(" ");
    }
    !this.checkboxTarget.checked && this.shotCheckboxErrMsg(true);
  }
  onChange() {
    this.shotCheckboxErrMsg(!this.checkboxTarget.checked);
  }
}
