import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    action: Boolean,
    id: String
  }

  connect(){
   
    if(this.actionValue) {
      this.toggleModal()
    }
   
  }

  toggleModal(id){
    const body = document.querySelector('body')
    if (this.idValue !== "#js-appmodal-postulation-canceled"){
      const modal = document.querySelector(".modal-message-accept")
      modal.classList.toggle('opacity-0')
      modal.classList.toggle('pointer-events-none')
    }else{
      const modal = document.querySelector(".modal-message-cancel")
      modal.classList.toggle('opacity-0')
      modal.classList.toggle('pointer-events-none')
    }
   body.classList.toggle('modal-active')
  }

  closeModal() {
    document.onkeydown = function(evt) {
      evt = evt || window.event
      var isEscape = false
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
      } else {
        isEscape = (evt.keyCode === 27)
      }
      if (isEscape && document.body.classList.contains('modal-active')) {
        this.toggleModal()
      }
    };  

    const overlay = document.querySelector('.modal-overlay')
    overlay.addEventListener('click', this.toggleModal)
    
    var closemodal = document.querySelectorAll('.modal-close')
    for (var i = 0; i < closemodal.length; i++) {
      closemodal[i].addEventListener('click', this.toggleModal)
    }
  }
}