import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'inputRadio'
    ]

  connect(){
    this.inputRadioTargets.forEach(this.selectOnConnect.bind(this))
  }
  
  clickLabel(event){
    // conecta el label marcado
    this.toggleSelected(event);
  }
    

	toggleSelected(event) {
		// si el label no está seleccionado
    if(!event.target.classList.contains('selected') && !event.target.nextElementSibling.disabled ){
			// lo selecciono y desselecciono los demás llamando a la función unToggleSelected
			this.unToggleSelected(event);
      event.target.classList.add('selected');
			event.target.nextElementSibling.checked = true;
      this.toggleEconomicAgreement(event.target.nextElementSibling);
    }
  }

	unToggleSelected(event){
		// selecciono a todos los elementos que estén "pintados" con .genre-toggle.selected
		var selected_elements = event.target.closest(".radio-buttons").querySelectorAll(".genre-toggle.selected")
		selected_elements.forEach((element) => {
			// y le saco el checked y la clase que lo pinta
			element.classList.remove('selected');
			element.nextElementSibling.checked = false;
      this.unToggleEconomicAgreement(element.nextElementSibling);

		})
		
	}

  toggleEconomicAgreement(element) {
    switch (element.value) {
      case "other":
        var input = element.closest(".radio-buttons").querySelector(".other.hidden")
        input.classList.remove('hidden');
        break;
      case "percentage_of_tickets":
        var input = element.closest(".radio-buttons").querySelector(".percentage.hidden")
        input.classList.remove('hidden');
        break;
    }

  }

  unToggleEconomicAgreement(element) {
    switch (element.value) {
      case "other":
        var input = element.closest(".radio-buttons").querySelector(".other")
        input.classList.add('hidden');
        input.firstElementChild.firstElementChild.value = ''
        break;
      case "percentage_of_tickets":
        var input = element.closest(".radio-buttons").querySelector(".percentage")
        input.classList.add('hidden');
        input.firstElementChild.firstElementChild.value = ''
        break;
    }
  }

  selectOnConnect(elem){
      if (elem.checked && elem.disabled){
        elem.previousSibling.previousSibling.classList.add('selected');
        elem.previousSibling.previousSibling.classList.add('disabled');
        this.toggleEconomicAgreement(elem);
      }
      else if (elem.disabled){
        elem.previousSibling.previousSibling.classList.add('disabled')
      }
      else if (elem.checked){
        elem.previousSibling.previousSibling.classList.add('selected');
        this.toggleEconomicAgreement(elem);
      }
  }
}
