import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "text" ]
  TEXT = this.textTarget.textContent;
  initialize(){
    if (this.TEXT.length > 100) {
      this.showLess();
    }
  }

  showMore() {
    event.preventDefault();
    const element = this.textTarget;
    element.textContent = this.TEXT;
    element.insertAdjacentHTML('beforeend', ' <a class="dummy-link" data-action="click->show-more#showLess">Ver menos</a>');

  }

  showLess() {
    event.preventDefault();
    const element = this.textTarget;
    element.textContent = this.TEXT.substring(0, 100);
    element.insertAdjacentHTML('beforeend', ' <a class="dummy-link" data-action="click->show-more#showMore">Ver más</a>');
  }
}