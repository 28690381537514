import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "input", "list"]
  static values = { url: String, venueProfileId: Number, venueId: Number }

  connect() {
    let allVenues = []
    const venues = Array.from(this.listTarget.children) 
    for (let i = 0; i < venues.length; i++) {
      allVenues.push(venues[i])
    }

    this.inputTarget.addEventListener("input", () => {
      setTimeout(() => {
        this.search(allVenues)
      }, 1000)
    })
  }

  search(allVenues) {
    const value = this.inputTarget.value
    const fetchUrl = `${this.urlValue}?query=${value}`
    fetch(fetchUrl, {
      headers: {accept: 'application/json'}
    })
    .then((response) => response.json())
    .then(data => this.handleVenueSearch(data, allVenues) )
  }

  handleVenueSearch(data, allVenues) {
    if (this.inputTarget.value.length >= 3) {
      this.listTarget.innerHTML = ""
      data?.forEach(element => {
        const classes = ['mt-1', 'border-opacity-70', 'cursor-pointer', 'border-neutral-30', 'border-b', 'pl-2', 'text-left', 'text-neutral-60', 'hover:text-opacity-70']
        const linkClasses = ['underline', 'whitespace-nowrap']
        let div = document.createElement('div')
        if (element.id === this.venueIdValue) {
          div.classList.add('text-pink')
        }
        if (element.state === "complete") {
          div.textContent = element.name
          div.classList.add(...classes)
          div.dataset.link = `/venue_profiles/${element.venue_profile_id}/toggle_venue?venue_id=${element.id}`
          div.dataset.action = "click->toggle-venue#loadVenue"
        } else {
          if (this.venueProfileIdValue === element.venue_profile_id) {
            let link = document.createElement('a')
            link.classList.add(...linkClasses)
            link.text = element.name
            link.href = `/venue_profile/add_social_media`
            div.classList.add(...classes)
            div.appendChild(link)
          }
        }
        this.listTarget.appendChild(div)
      });
    } else {
      this.listTarget.innerHTML = ""
      for (let i = 0; i < allVenues.length; i++) {
        this.listTarget.appendChild(allVenues[i]);
      }
    }
  }
}
