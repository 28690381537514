// Patch to ViewPort in Mobile browsers
// reference: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

export default class {
  static start() {
    this.calculateViewport()

    window.addEventListener('resize', this.calculateViewport)
  }

  static calculateViewport() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}

