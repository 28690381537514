import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.updateFilename();
  }

  updateFilename() {
    const input = this.element.querySelector("input");
    const filename = this.element.querySelector(".custom-file-button-text span");

    input.addEventListener("change", (event) => {
      const file = event.target.files[0];
      if (file) {
        filename.textContent = file.name;
        filename.classList.add("custom-underline"); // Agregar la clase break-word
      } else {
        filename.textContent = "";
        filename.classList.remove("custom-underline"); // Quitar la clase break-word si no hay archivo seleccionado
      }
    });
  }
}
