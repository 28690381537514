import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];

  connect() {
    this.showTab(0);
  }

  changeTab(event) {
    event.preventDefault();
    const index = event.currentTarget.dataset.index;
    this.showTab(index);
  }

  showTab(index) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active");
    });
    this.contentTargets.forEach((content) => {
      content.classList.remove("active");
    });

    this.tabTargets[index].classList.add("active");
    this.contentTargets[index].classList.add("active");
  }
}
