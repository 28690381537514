import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    size: String
  }

  connect(){
    this.loadModal();
  }

  loadModal(){
    var controller = this
    fetch(this.urlValue)
    .then((response) => response.text())
    .then((content) => {
      const modal = $('#js-appmodal-confirm')
      var modal_container
      modal_container = modal.find('.modal-container-confirm')
      modal_container.addClass(`modal-${this. sizeValue}`)
      modal.find('.modal-content').html(content)
      controller.closeModal();
      controller.toggleModal();
    });
  }
  
  toggleModal(){
    const body = document.querySelector('body')
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
  }

  closeModal() {
    document.onkeydown = function(evt) {
      evt = evt || window.event
      var isEscape = false
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
      } else {
        isEscape = (evt.keyCode === 27)
      }
      if (isEscape && document.body.classList.contains('modal-active')) {
        this.toggleModal()
      }
    };  

    const overlay = document.querySelector('.modal-overlay')
    overlay.addEventListener('click', this.toggleModal)
    
    var closemodal = document.querySelectorAll('.modal-close')
    for (var i = 0; i < closemodal.length; i++) {
      closemodal[i].addEventListener('click', this.toggleModal)
    }
  }
}