import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let controller = this
    document.querySelectorAll('input, textarea, select').forEach(function (input) {
      input.addEventListener('input', controller.handleInput)
    })
  }

  handleInput() {
    document.querySelectorAll('.btn-submit').forEach(function (btn) {
      btn.disabled = false
      btn.classList.replace("btn-info", "btn-submit-enabled")
    })
  }
}