import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
  connect(){ 
    this.initializeSlideSocialEvent()
  }


  initializeSlideSocialEvent(){
    new Splide(".splide-social-event-show-images-mobile", {
      perPage: 1,
      perMove: 1,
      rewind: true,
      type: "loop",
      arrows: false,
      cover: true,
      heightRatio: 0.6,
      autoplay: true,
    }).mount();
  }
}