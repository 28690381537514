import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    size: String,
    suspend: String,
    benefit: String
  }

  connect() {
    this.suspend()
  }

  loadModal() {
    var controller = this
    fetch(this.urlValue)
      .then((response) => response.text())
      .then((content) => {
        const modal = $('#js-appmodal')
        var modal_container
        modal_container = modal.find('.modal-container')
        modal_container.addClass(`modal-${this.sizeValue}`)
        modal.find('.modal-content').html(content)
        if (document.getElementById("form-payment") != null) controller.addHiddenInput(); 
        controller.closeModal();
        controller.toggleModal();
      });
  }

  suspend() {
    if (this.suspendValue == 'true') {
      this.loadModal();
    }
  }


  toggleModal() {
    const body = document.querySelector('body')
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
  }

  toggleModalNotBenefits() {
    const body = document.querySelector('body')
    const modal = document.querySelector('.modal')
    modal.classList.remove('opacity-1')
    modal.classList.add('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
  }

  closeModal() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape' && document.body.classList.contains('modal-active')) {
        this.toggleModal()
      }
    })

    const overlay = document.querySelector('.modal-overlay')
    overlay.addEventListener('click', this.toggleModal)

    var closemodal = document.querySelectorAll('.modal-close')
    for (var i = 0; i < closemodal.length; i++) {
      closemodal[i].addEventListener('click', this.toggleModal)
    }
  }

  addHiddenInput() {
    let input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "frecuency");
    input.setAttribute("value", this.benefitValue);
    document.getElementById("form-payment").appendChild(input);
  }
}