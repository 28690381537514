import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const desktopImageInput = this.element.querySelector("#desktop-image-input");
    const mobileImageInput = this.element.querySelector("#mobile-image-input");
    const previewImage = this.element.querySelector("#previewImage");

    desktopImageInput.addEventListener("change", (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (event) {
        previewImage.src = event.target.result;
      };

      reader.readAsDataURL(file);
    });

    mobileImageInput.addEventListener("change", (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (event) {
        previewImage.src = event.target.result;
      };

      reader.readAsDataURL(file);
    });
  }
}
