import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  
  open(event) {
    gtag("event", "Chat", {
      event_category: `Open`,
      event_label: `Chat Opened`,
      event_value: event.target.dataset.id,
    });
  }

  answer(event) {
    gtag("event", "Chat", {
      event_category: "Answer",
      event_label: "User Answered",
      event_value: event.target.dataset.id,
    });
  }

  close(event) {
    gtag("event", "Chat", {
      event_category: "Close",
      event_label: "Chat Closed",
      event_value: event.target.dataset.id,
    });
  }
}
