import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["linkSelect", "customLinkInput", "customLinkField"];

  connect() {
    this.updateCustomLinkInput();
    this.linkSelectTarget.addEventListener("change", () => {
      this.updateCustomLinkInput();
    });
  }

  updateCustomLinkInput() {
    if (this.linkSelectTarget.value === "custom_link") {
      this.customLinkInputTarget.style.display = "block";
      this.customLinkFieldTarget.removeAttribute("disabled");
    } else {
      this.customLinkInputTarget.style.display = "none";
      this.customLinkFieldTarget.setAttribute("disabled", "disabled");
    }
  }
}
