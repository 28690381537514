import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["previewImage", "toggleSwitch"];

  connect() {
    this.toggleImage();
  }

  toggleImage() {
    const isChecked = this.toggleSwitchTarget.checked;
    const imageAttribute = isChecked ? "data-image-url" : "data-mobile-image-url";
    this.previewImageTarget.src = this.previewImageTarget.getAttribute(imageAttribute);
  }
}
