import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['link', 'trashCan', 'deleteModal']

  initialize() {
    this.boundOnKeyDown = this.onKeyDown.bind(this)
  }

  connect() {
    window.addEventListener("keydown", this.boundOnKeyDown)
  }

  toggleDeleteModal(event) {
    this.deleteModalTarget.toggleAttribute('hidden')
  }

  onKeyDown(event) {
    if (event.keyCode === 27) {
      if (!this.deleteModalTarget.hasAttribute('hidden')) this.toggleDeleteModal()
    }
  }
}
