import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["imageInput", "imagePreview"];

  connect() {
    this.imageInputTarget.addEventListener("change", this.previewImage.bind(this));
  }

  previewImage(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      this.imagePreviewTarget.src = e.target.result;
    };

    reader.readAsDataURL(file);
  }
}
