import BaseImageInputController from "./base_image_input_controller";

export default class extends BaseImageInputController {
  static targets = [
    "input",
    "addSecondaryImage",
    "message",
    "main",
    "inputMain",
    "inputCacheMain",
    "photo",
    "inputPhoto",
    "inputCachePhoto",
    "mainPictureDestroy",
    "mainCropper",
  ];

  initialize() {
    this.maxImagesUpload = 4;
  }

  connect() {
    this.loadSecondaryImagesInput();
  }

  loadSecondaryImagesInput() {
    var count_to_load =
      this.maxImagesUpload -
      document.getElementsByClassName("secondary-photo-box").length;

    for (var i = 0; i < count_to_load; i += 1) {
      this.addSecondaryImageTarget.click();
    }
  }

  compressAndShowImage(file, cropperElement, ratio) {
    const cropper = cropperElement.cropper;
    let cropperModal = cropperElement.querySelector("#cropper-modal");
    let cropperModalImg = cropperModal.querySelector("img");

    const fr = new FileReader();
    fr.onload = () => {
      const img = new Image();
      img.onload = () => {
        const maxSizeWidth = 1920;
        const maxSizeHeight = 650;
        const width = img.width;
        const height = img.height;
        const resizeRatio = Math.min(maxSizeWidth / width, maxSizeHeight / height);
        const canvas = document.createElement("canvas");
        canvas.width = width * resizeRatio;
        canvas.height = height * resizeRatio;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width * resizeRatio, height * resizeRatio);

        canvas.toBlob((blob) => {
          const callback = new FileReader();
          callback.onload = () => {
            cropperModal.style.display = "flex";
            cropperModalImg.src = callback.result;
            cropper.rebuild({ ratio });
          };
          callback.readAsDataURL(blob);
        }, file.type, 1.0);
      };
      img.src = fr.result;
    };

    fr.readAsDataURL(file);
  }

  showImage(event) {
    var file = event.target.files[0];
    var image_container = event.target
      .closest(".nested-fields")
      .querySelector(".image-field .image-container");
    var card = event.target
      .closest(".nested-fields")
      .querySelector(".image-field .image-input-card");
    var img = event.target
      .closest(".nested-fields")
      .querySelector(".image-field img");
    if (file) {
      var fr = new FileReader();
      fr.onload = function () {
        card.hidden = true;
        image_container.hidden = false;
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  }

  showImageMain(event) {
    var file = event.target.files[0];
    const cropperElement = event.target.closest("#cropper");
    const maxFileSize = 5485760; // Corresponde a 5MB
    if (file && this.inWhitelist(file.type)) {
      this.removeImgTypeError(cropperElement);
      if (file.size > maxFileSize) {
        this.compressAndShowImage(file, cropperElement, 2);
      } else {
        const fr = new FileReader();
        fr.onload = () => {
          const cropper = cropperElement.cropper;
          let cropperModal = cropperElement.querySelector("#cropper-modal");
          let cropperModalImg = cropperModal.querySelector("img");
          cropperModal.style.display = "flex";
          cropperModalImg.src = fr.result;
          cropper.rebuild({ ratio: 2 });
        };
        fr.readAsDataURL(file);
      }
    } else {
      this.appendImgTypeError(cropperElement);
      this.inputMainTarget.value = null;
    }
  }

  addMainPicture() {
    this.inputMainTarget.click();
  }

  removeMainPicture(event) {
    event.stopPropagation();
    this.inputMainTarget.value = null;
    this.inputCacheMainTarget.value = null;
    this.mainTarget.src = "";
    let label = event.target
      .closest("#cropper")
      .querySelector("label.custom-file-label");
    if (label) label.innerHTML = "";
    this.mainPictureDestroyTarget.value = "false";
    this.enableSubmit()
  }

  addSecondaryImage(event) {
    event.stopPropagation();
    let inputElement = event.target
      .closest(".nested-fields")
      .querySelector("input");
    inputElement.value = null;
    inputElement.click();
    this.enableSubmit()
  }

  showSecondaryImage(event) {
    var file = event.target.files[0];
    const cropperElement = event.target.closest("#cropper");
    const maxFileSize = 5485760; // Corresponde a 5MB
    if (file && this.inWhitelist(file.type)) {
      this.removeImgTypeError(cropperElement);
      if (file.size > maxFileSize) {
        this.compressAndShowImage(file, cropperElement, 1.6);
      } else {
        const fr = new FileReader();
        fr.onload = () => {
          const cropper = cropperElement.cropper;
          let cropperModal = cropperElement.querySelector("#cropper-modal");
          let cropperModalImg = cropperModal.querySelector("img");
          cropperModal.style.display = "flex";
          cropperModalImg.src = fr.result;
          cropper.rebuild({ ratio: 1.6 });
        };
        fr.readAsDataURL(file);
      }
    } else {
      this.appendImgTypeError(cropperElement);
      let inputElement = event.target.closest(".nested-fields").querySelector("input");
      inputElement.value = null;
    }
  }
  

  removeSecondaryImage(event) {
    event.stopPropagation();
    let nestedFields = event.target.closest(".nested-fields");
    nestedFields.querySelector(".remove-secondary-image").click();
    let label = nestedFields.querySelector("label.custom-file-label");
    if (label) label.innerHTML = "";
    var box = event.target.closest(".box-secondary-image");
    if (box != null) box.classList.add("hidden");
    this.addSecondaryImageTarget.click();
    this.enableSubmit()
  }

  enableSubmit() {
    document.querySelectorAll('.btn-submit').forEach(function (btn) {
      btn.disabled = false
      btn.classList.replace("btn-info", "btn-submit-enabled")
    })
  }

}
