import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['optionsContainer', 'option', 'optionSelected']

  connect(){
    this.handleSelect();
    this.optionSelectedTarget.addEventListener("click", (event) => {
      this.optionsContainerTarget.classList.toggle("active");
      event.stopPropagation(); //Close custom select on click outside
    });
    this.toggleSelect(); //Close custom select on click outside
  }

  handleSelect(){
    const optionsList = this.optionTargets;

    optionsList.forEach(option => {
      option.addEventListener("click", (event) => {
        option.querySelector("input").checked = true; //Selecciona el radio button cuando se clickea el div
        this.optionSelectedTarget.innerHTML = option.querySelector("label").innerHTML;
        this.optionSelectedTarget.value = option.querySelector("label").innerHTML;
        this.optionsContainerTarget.classList.remove("active");
      });
    })
  }

  toggleSelect() {
    const body = document.querySelector('body');
    body.addEventListener("click", (e) => {
      const option = document.querySelector('.options-container.active');
      if (option) {
        option.classList.remove("active");
      }
    });
  }
}
