import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dropdownCollapsible', 'dropdownIcon', 'selectedProfessions']
  static values = { currentUser: String }

  connect() {
    this.showSelectedProfessions();
    this.handleOtherProfession();
    this.closeDropdown();
  }

  showSelectedProfessions() {
    const showSelectedProfessionsSpan = document.createElement('span');
    showSelectedProfessionsSpan.classList.add('selected-profession');
    const selectedCheckboxList = []
    const selectedCheckboxes = this.element.querySelectorAll('input[type="checkbox"]');
    const allSelectedCheckboxes = this.element.querySelectorAll('input[type="checkbox"]:checked');

    const checkArrayLength = (arr, span) => {
      if (arr.length == 0 ) {
        showSelectedProfessionsSpan.textContent = 'Elegí tu profesión'
      } else if (arr.length > 1) {
        span.textContent = arr[0] + ' ' + '...';
      } else if (arr.length == 1) {
        span.textContent = arr[0] 
      }
    }

    allSelectedCheckboxes.forEach(checkbox => {
      selectedCheckboxList.push(checkbox.parentElement.textContent);
    });
    checkArrayLength(allSelectedCheckboxes, showSelectedProfessionsSpan)

    if (allSelectedCheckboxes.length == 0) {
      showSelectedProfessionsSpan.textContent = 'Elegí tu profesión'
    } else {
      const firstSelectedCheckbox = this.element.querySelectorAll('input[type="checkbox"]:checked')[0].parentElement.textContent;
      if (selectedCheckboxList.length == 1) {
        showSelectedProfessionsSpan.textContent = firstSelectedCheckbox
      } else if (selectedCheckboxList.length > 1) {
        showSelectedProfessionsSpan.textContent = firstSelectedCheckbox + ' ' + '...';
      }
    }
    
    selectedCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', function(event) {
        if (this.checked) {
          selectedCheckboxList.push(checkbox.parentElement.textContent);
        } else {
          const pos = selectedCheckboxList.indexOf(checkbox.parentElement.textContent)
          selectedCheckboxList.splice(pos, 1)
        }
        checkArrayLength(selectedCheckboxList, showSelectedProfessionsSpan)
      });
    });
    this.selectedProfessionsTarget.appendChild(showSelectedProfessionsSpan);
  }

  handleOtherProfession() {
    const selected = this.element.querySelectorAll('input[type="checkbox"]')
    const hiddenField = document.getElementsByClassName('hidden-field');

    if (this.element.querySelector('input[value="other"]').checked) {
      hiddenField[0].classList.toggle('hidden');
    }

    selected.forEach(checkbox => {
      checkbox.addEventListener('change', (event) => {
        if (checkbox.value === 'other') {
          if (checkbox.checked) {
            hiddenField[0].classList.toggle('hidden')
            this.toggleDropdown()
          } else {
            hiddenField[0].classList.toggle('hidden')
          };
        } 
      });
    });
  }

  toggleDropdown() {
    if (this.currentUserValue === "true") {
      if (this.isCollapsed()) {
        this.dropdownCollapsibleTarget.style.height = this.dropdownCollapsibleTarget.scrollHeight + 'px';
      }
      else { 
        this.dropdownCollapsibleTarget.style.height = 0 + 'px';
      }
      this.dropdownIconTarget.classList.toggle('rotate-180')
      this.dropdownCollapsibleTarget.classList.toggle('collapsed')
    } else {
      const modalController = this.application.getControllerForElementAndIdentifier(document.getElementById("alert"),"freemium");
      modalController.freemium();
    }
  }

  isCollapsed() {
    return this.dropdownCollapsibleTarget.classList.contains('collapsed')
  }

  closeDropdown() {
    document.addEventListener('click', (event) => {
      if (!this.isCollapsed() && !this.element.contains(event.target)) {
        this.toggleDropdown()
      }
    })
  }
}