import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    favoriteUrl: String,
    currentUser: String
  }
  static targets = ['heart']

  connect() {
    this.modalListener();
  }

  async toggleFavorite() {
    if (this.currentUserValue === 'true') {
      this.heartTarget.classList.remove('favorite-heart-animation')
      const csrfToken = document.querySelector("[name='csrf-token']").content
      return fetch(this.favoriteUrlValue, {
        method: 'POST',
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json"
        }
      }).then(() => {
        this.heartTarget.classList.toggle('fill-current')
        this.heartTarget.classList.add('favorite-heart-animation')
      })
    } else {
      const modalController = this.application.getControllerForElementAndIdentifier(document.getElementById("alert"),"freemium");
      modalController.freemium();
    }
  }
}