import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dropdownCollapsible', 'dropdownIcon']

  toggleDropdown() {
    if (this.isCollapsed()) {
      this.dropdownCollapsibleTarget.style.height = this.dropdownCollapsibleTarget.scrollHeight + 'px';
    }
    else {      
      this.dropdownCollapsibleTarget.style.height = 0 + 'px';
    }
    this.dropdownIconTarget.classList.toggle('rotate-180')
    this.dropdownCollapsibleTarget.classList.toggle('collapsed')
  }

  isCollapsed() {
    return this.dropdownCollapsibleTarget.classList.contains('collapsed')
  }
}
