import { Controller } from "stimulus"

export default class extends Controller {
  connect(){ 
    let tooltips = document.getElementsByClassName("tooltip")
    const Clipboard = require("clipboard");
      var clipboard = new Clipboard('#share-event');
      clipboard.on('success', function(e) {
          for (let tooltip of tooltips) {
            tooltip.classList.remove('invisible')
            tooltip.classList.add('visible')
            setTimeout(function(){           
              tooltip.classList.remove('visible')
              tooltip.classList.add('invisible') }, 800);
          } 
      });
  }

}