import { Controller } from "stimulus";
import gtag from "../../src/analytics";

export default class extends Controller {
  static targets = ["params", "genreOption", "provinceOption"];

  search() {
    const provinces = this.paramsTarget.dataset.provinces
    const genres = this.paramsTarget.dataset.genres
    if (provinces && provinces.length > 0)
      gtag("event", "Search", {
        event_category: "Search - Province",
        event_label: "Province Filter",
        event_value: provinces,
      });
    if (genres && genres.length > 0)
      gtag("event", "Search", {
        event_category: "Search - Genres",
        event_label: "Genre Filter",
        event_value: genres,
      });
  }
}
