import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
 
  connect(){
    this.initializeSlideSocialEvent()
  }

  initializeSlideSocialEvent(){
    var splide = new Splide( '.splide-benefits-plan', {
      arrows: false,
      type: 'slide',
      padding: '12%',
      perPage: 1,
      perMove: 1,
      start: 2,
      }
  ).mount();

    var inspiration = [4,5,6]
    var creation = [7,8]

    if (document.getElementById('id_splide_title').textContent == 'Inspiración') {

      inspiration.forEach(element => {
        document.getElementById('benefit-'+element+'-text').classList.add('text-no-benefit');
        document.getElementById('benefit-'+element+'-disabled').hidden = false;
        document.getElementById('benefit-'+element+'-enabled').hidden = true;
      });
      creation.forEach(element => {
        document.getElementById('benefit-'+element+'-text').classList.add('text-no-benefit');
        document.getElementById('benefit-'+element+'-disabled').hidden = false;
        document.getElementById('benefit-'+element+'-enabled').hidden = true;
      });
    }

    splide.on('visible', function(e) {
      let title = e.slide.lastElementChild.lastElementChild.firstElementChild.textContent

      if (title == 'Inspiración') {

        inspiration.forEach(element => {
          document.getElementById('benefit-'+element+'-text').classList.add('text-no-benefit');
          document.getElementById('benefit-'+element+'-disabled').hidden = false;
          document.getElementById('benefit-'+element+'-enabled').hidden = true;
        });
        creation.forEach(element => {
          document.getElementById('benefit-'+element+'-text').classList.add('text-no-benefit');
          document.getElementById('benefit-'+element+'-disabled').hidden = false;
          document.getElementById('benefit-'+element+'-enabled').hidden = true;
        });
      }

      if (title == 'Creación') {

        inspiration.forEach(element => {
          document.getElementById('benefit-'+element+'-text').classList.remove('text-no-benefit');
          document.getElementById('benefit-'+element+'-disabled').hidden = true;
          document.getElementById('benefit-'+element+'-enabled').hidden = false;
        });
        creation.forEach(element => {
          document.getElementById('benefit-'+element+'-text').classList.add('text-no-benefit');
          document.getElementById('benefit-'+element+'-disabled').hidden = false;
          document.getElementById('benefit-'+element+'-enabled').hidden = true;
        })
      }

      if (title == 'Imparable') {

        inspiration.forEach(element => {
          document.getElementById('benefit-'+element+'-text').classList.remove('text-no-benefit');
          document.getElementById('benefit-'+element+'-disabled').hidden = true;
          document.getElementById('benefit-'+element+'-enabled').hidden = false;
        });
        creation.forEach(element => {
          document.getElementById('benefit-'+element+'-text').classList.remove('text-no-benefit');
          document.getElementById('benefit-'+element+'-disabled').hidden = true;
          document.getElementById('benefit-'+element+'-enabled').hidden = false;
        });
      }
    })
  }
}