import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['scrollElement']

  // Scrolls automatically to the first scrollElement target present in the DOM (after the DOM content is loaded)
  initialize() {
    this.boundScrollToErrors = this.scrollToErrors.bind(this);
  }

  connect() {
    window.addEventListener("DOMContentLoaded", this.boundScrollToErrors)
  }

  disconnect() {
    window.removeEventListener("DOMContentLoaded", this.boundScrollToErrors)
  }

  scrollToErrors() {
    if (this.scrollElementTargets.length > 0) this.scrollElementTargets[0].scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}
