import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const pasteBox = document.getElementById("no-paste");
    pasteBox.onpaste = e => {
      e.preventDefault();
      return false;
    };
  }
}