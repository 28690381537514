import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container']
  static values = { path: String }

  openPopup() {
    this.loadChatBox(this.pathValue)
  }

  minimizeChat() {
    this.loadChatBox(this.pathValue)
  }

  closeContainer() {
    this.containerTarget.innerHTML = ''
    this.hideContainer()
  }

  closePopup() {
    this.hidePopup()
  }

  clickChat(event) {
    const { chatPath } = event.currentTarget.dataset

    this.loadChatBox(chatPath)
  }

  async loadChatBox(path) {
    this.showPopup()
    this.showContainer()

    const result = await $.get(path)
    this.containerTarget.innerHTML = result
  }

  showPopup() { this.element.classList.remove('invisible') }
  hidePopup() { this.element.classList.add('invisible') }

  showContainer() { this.containerTarget.classList.remove('invisible') }
  hideContainer() { this.containerTarget.classList.add('invisible') }
}


