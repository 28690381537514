import { Controller } from "stimulus"

export default class extends Controller {
  loadModal(){
    closeModal();
    toggleModal();
  
    function toggleModal(){
      const body = document.querySelector('body')
      const modal = document.querySelector('.modal')
      modal.classList.toggle('opacity-0')
      modal.classList.toggle('pointer-events-none')
      body.classList.toggle('modal-active')
    }

    function closeModal() {
      document.onkeydown = function(evt) {
        evt = evt || window.event
        var isEscape = false
        if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc")
        } else {
          isEscape = (evt.keyCode === 27)
        }
        if (isEscape && document.body.classList.contains('modal-active')) {
          toggleModal()
        }
      };
      
    
      let params = new URLSearchParams(window.location.href)
      let locality = params.get('filter[locality]')
      if (locality != null) {
        let valueProvince = document.getElementById('filter_province').value
        let localityDiv = document.getElementById('musical-project-locality')
        let loadLocality = document.getElementById('filter_locality')

        $.ajax({
          url: 'project_finder/load_localities',
          data: {
            'selected_province': valueProvince
          },
          success:  (result) => {
            loadLocality.replaceChildren()
            let optionBlank = document.createElement('option')
            optionBlank.value = ''
            optionBlank.text = ''
            let optionFirst = document.createElement('option')
            optionFirst.value = locality
            optionFirst.text = locality
            let tmp = result.map(element => {
              if (element != locality){
                let option = document.createElement('option')
                option.value = element
                option.text = element
                return option
              }
            });
            loadLocality.append(optionFirst,...tmp)
          }
        })
        localityDiv.classList.remove('hidden')
      }

      const overlay = document.querySelector('.modal-overlay')
      overlay.addEventListener('click', toggleModal)
      
      var closemodal = document.querySelectorAll('.modal-close')
      for (var i = 0; i < closemodal.length; i++) {
        closemodal[i].addEventListener('click', toggleModal)
      }
    }
  }
}
