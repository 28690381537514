import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    size: String
  }

  loadModal(){
    fetch(this.urlValue)
    .then((response) => response.text())
    .then((content) => {
      const modal = $('#js-appmodal')
      modal.find('.modal-content').html(content)
      modal.modal('show')
      modal.on('ajax:error', (event) => {
        let [ , , xhr] = event.detail
        modal.find('.modal-content').html(xhr.response)
      });
    });
  }
}