import { Controller } from "stimulus"

const INTERVAL_TIME = 60*1000;

export default class extends Controller {
  static targets = ['messages', 'scroll', 'input', 'observable', 'button']
  static values = { path: String }

  initialize () {
    this.messagesObserverCallback = this.messagesObserverCallback.bind(this)
    this.intervalHandler = this.intervalHandler.bind(this)
    this.handleInputKeyPress = this.handleInputKeyPress.bind(this)
  }

  connect() {
    this.setObserver()
    this.loadMessages()

    this.intervalTimer = setInterval(this.intervalHandler, INTERVAL_TIME); // 1 minute
    this.inputTarget.addEventListener('keypress', this.handleInputKeyPress)
  }

  disconnect() {
    if (this.messagesObserver) this.messagesObserver.disconnect()
    if (this.intervalTimer) clearInterval(this.intervalTimer)
    this.inputTarget.removeEventListener('keypress', this.handleInputKeyPress)
  }

  focusChat() {
    this.scrollTarget.scrollTop = this.scrollTarget.scrollHeight;
    this.inputTarget.value = '';
    this.inputTarget.focus()
  }

  async loadMessages() {
    if (!this.hasMessagesTarget) return;

    const { readChatPath, messagesPath } = this.messagesTarget.dataset;

    await $.get(messagesPath, (result) => this.messagesTarget.insertAdjacentHTML('afterbegin', result))
    this.focusChat()

    await $.ajax({ method: 'PUT', url: readChatPath })

    //TODO: Maybe if any fetch to backend faild show a reload button
  }

  setObserver() {
    if (this.hasObservableTarget) {
      this.messagesObserver = new IntersectionObserver(this.messagesObserverCallback, { threshold: 0.5, root: this.scrollTarget })
      this.messagesObserver.observe(this.observableTarget)
    }
  }

  messagesObserverCallback(entries) {
    const entry = entries[0];

    if (entry.intersectionRatio < 0.5) return;
    if (!this.messagesTarget) return;

    const firstMessageLoaded = this.messagesTarget.querySelector('[data-type=message]:first-child');
    if (!firstMessageLoaded) return;

    const { messageId } = firstMessageLoaded.dataset;
    this.loadOldsMessages(messageId)
  }

  intervalHandler() {
    const lastMessageLoaded = this.messagesTarget.querySelector('[data-type=message]:last-child');
    if (!lastMessageLoaded) return;

    const { messageId } = lastMessageLoaded.dataset;
    this.loadNewsMessages(messageId)
  }

  async loadNewsMessages(overMessageId) {
    const { messagesPath } = this.messagesTarget.dataset;

    const result = await $.get(messagesPath, { over_id: overMessageId })
    this.messagesTarget.insertAdjacentHTML('beforeend', result);

    this.scrollTarget.scrollTop = this.scrollTarget.scrollHeight;
  }

  async loadOldsMessages(untilMessageId) {
    const { messagesPath } = this.messagesTarget.dataset;

    const result = await $.get(messagesPath, { until_id: untilMessageId })
    //TODO: Maybe if any fetch to backend faild show a reload button

    if (result.trim() === '') {
      this.messagesObserver.disconnect()

      return
    }

    const oldHeight = this.scrollTarget.scrollHeight;
    const oldScroll = this.scrollTarget.scrollTop;

    this.messagesTarget.insertAdjacentHTML('afterbegin', result);

    const newHeight = this.scrollTarget.scrollHeight;

    this.scrollTarget.scrollTop = oldScroll + newHeight - oldHeight;
  }

  handleInputKeyPress(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.clickSendButton();
    }
  }

  clickSendButton() {
    this.buttonTarget.click();
    this.inputTarget.style.height = 'auto'
  }
}
