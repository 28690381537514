import { Controller } from "stimulus"

export default class extends Controller {
  static values = { currentUser: String }

  loadModal(){
    if (this.currentUserValue === "true") {
      closeModal();
      toggleModal();
    
      function toggleModal(){
        const body = document.querySelector('body')
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
        body.classList.toggle('modal-active')
      }
  
      function closeModal() {
        document.onkeydown = function(evt) {
          evt = evt || window.event
          var isEscape = false
          if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc")
          } else {
            isEscape = (evt.keyCode === 27)
          }
          if (isEscape && document.body.classList.contains('modal-active')) {
            toggleModal()
          }
        };  
  
        const overlay = document.querySelector('.modal-overlay')
        overlay.addEventListener('click', toggleModal)
        
        var closemodal = document.querySelectorAll('.modal-close')
        for (var i = 0; i < closemodal.length; i++) {
          closemodal[i].addEventListener('click', toggleModal)
        }
      }
    } else {
      const modalController = this.application.getControllerForElementAndIdentifier(document.getElementById("alert"),"freemium");
      modalController.freemium();
    }
  }
}
