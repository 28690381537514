import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["inputFieldCity", "dropdownProvince", "storeField"];
  
  connect(){
    this.updateInputStatus();
  }

  updateInputStatus() {
    const selectedValue = this.dropdownProvinceTarget;
    const cityField = this.inputFieldCityTarget;
    const storeField = this.storeFieldTarget;
    cityField.disabled = false;
    cityField.required = true;
    storeField.required = false;
    storeField.disabled = true;
    if (selectedValue.value === 'Tienda Online') {
      cityField.disabled = true;
      cityField.required = false;
      storeField.required = true;
      storeField.disabled = false;
    }
  }
}
