import { Controller } from "stimulus"
import { Spanish } from "flatpickr/dist/l10n/es.js"
export default class extends Controller {

  static targets = [
    'inputDate',
    'divDate'
    ]

  connect(){
    window.onload = this.setUpPreviousCalendar()
  }

  initializeFlatpickr(){
    flatpickr(this.inputDateTarget,
      {
        "locale": Spanish,
        disableMobile: true,
        dateFormat: "d-m-Y",
        maxDate: "today"  
      }
    );
  }

  setUpPreviousCalendar(){
    let lastValue = this.inputDateTarget.value

    if (lastValue){
      this.flatpickrDaysTarget = this.initFlatpickr(this.inputDateTarget, 'single', lastValue)
      } 
    else {
      this.initializeFlatpickr()
    }
  }

  formatValue(value){
    let str = value.split('-')
    str = str[2] + '-' + str[1] + '-' + str[0]
    return str
  }

  initFlatpickr(element, mode, value){
    let controller = this
    return flatpickr(
      element,
      {
        "locale": Spanish,
        disableMobile: true,
        dateFormat: "d-m-Y",
        maxDate: "today",
        mode: mode,
        defaultDate: this.formatValue(value)
      }
    );
  }
}