import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['showDayCheckbox', 'showDayLabel'];

  displayPickedShowDates() {
    let amount = 0;
    if (this.checkLabels()) {
      this.showDayCheckboxTargets.forEach((element) => {
        if (!element.nextElementSibling.classList.contains("selected")) {
          element.nextElementSibling.style.display = "none";
        } else {
          element.nextElementSibling.classList.add(
            "cursor-default",
            "requested"
          );
          amount += 1;
        }
      });
      this.moveOnModal(amount);
    }
  }

  checkLabels() {
    //this method tell us if AT LEAST one of the show dates was selected
    var check = false;
    this.showDayCheckboxTargets.forEach((element) => {
      if (element.nextElementSibling.classList.contains("selected")) {
        check = true;
      }
    });
    return check;
  }

  toggleSelected(event) {
    var showDay = event.target.nextElementSibling;
    if (showDay.classList.contains("cursor-pointer")) {
      // if has cursor-pointer that means that is available
      if (showDay.classList.contains("selected")) {
        if (!showDay.classList.contains("requested")) {
          showDay.classList.remove("selected", "border", "border-primary-pink");
        } else {
          event.target.checked = true;
        }
      } else {
        showDay.classList.add("selected", "border-2", "border-primary-pink");
      }
    }
  }

  // this method changes the buttons on the modal
  toggleButtons(status) {
    this.handleArray(["back-to-all-dates", "submit-dates"], status);
  }

  moveBack() {
    document.getElementById("availableDate").innerHTML = "Fechas disponibles";
    document.getElementById("amountSelected").innerHTML =
      "Seleccioná las fechas a solicitar";
    this.showDayCheckboxTargets.forEach((element) => {
      element.nextElementSibling.style.display = "block";
      if (element.nextElementSibling.classList.contains("selected")) {
        element.nextElementSibling.classList.remove("requested");
      }
    });

    this.handleArray(["confirm-section"], true);
    this.handleArray(["request-section"], false);
  }

  moveOnModal(amount) {
    document.getElementById("availableDate").innerHTML = "Confirmar solicitud";
    const dates = amount == 1 ? " fecha seleccionada" : " fechas seleccionadas";
    document.getElementById("amountSelected").innerHTML =
      "Vas a solicitar tocar en las siguientes fechas: <br>" + amount + dates;

    this.handleArray(["confirm-section"], false);
    this.handleArray(["request-section"], true);
  }
  // this method show/hide the buttons on the modal

  handleArray(array, status) {
    array.forEach((element) => {
      document.getElementById(element).hidden = status;
    });
  }
  clickShowDayCheckbox() {
    this.toggleSelected(event);
  }
}

