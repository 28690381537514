import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "tooltip"]

  connect() {
    console.log(this.element)
  }

  showCheckboxErrMsg(show = false) {
    if (show) {
      this.tooltipTarget.style.opacity = '1'
      this.tooltipTarget.style.zIndex = '1'
    } else {
      this.tooltipTarget.style.opacity = '0'
      this.tooltipTarget.style.zIndex = '0'
    }
  }

  submitForm(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.checkboxTarget.checked) {
      this.element.submit()
    } else {
      this.showCheckboxErrMsg(true)
    }
  }

  onChange() {
    this.showCheckboxErrMsg(!this.checkboxTarget.checked)
  }
}
