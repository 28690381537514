import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
  connect(){ 
    this.initializeSlideSocialEvent()
  }


  initializeSlideSocialEvent(){
    new Splide(".splide-social-event-show-dates-mobile", {
      rewind: true,
      pagination: false,
      autoWidth: true,
      arrows: false,
    }).mount();
  }
}