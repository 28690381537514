import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['checkbox']

  submitForm(event) {
    let filterName = event.target.htmlFor
    let checkbox = document.getElementById(filterName)
    checkbox.checked = !checkbox.checked
    this.element.submit()
  }

  submitFormMobile(event) {
    if (event.target.type != 'checkbox') {
      let filterName = event.target.htmlFor
      let checkbox = document.getElementById(`${filterName}_mobile`)
      checkbox.checked = !checkbox.checked
    }
    this.element.submit()
  }
}