import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    const latitude = this.data.get('latitude')
    const longitude = this.data.get('longitude')
    const zoom = Number(this.data.get('zoom') || 16)

    const position = new google.maps.LatLng(latitude, longitude)

    this.map = new google.maps.Map(this.element, {center: position, zoom: zoom, disableDefaultUI: true, fullscreenControl: true })
    this.marker = new google.maps.Marker({ map: this.map, position })
    this.marker.setVisible(true)
  }
}

