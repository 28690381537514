import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'musicianGenderCheckbox',
    'musicianGenderLabel',
    ]

  connect(){
    this.selectOnConnect(this.musicianGenderCheckboxTargets)
  }

  clickMusicianGenderCheckbox(){
    this.toggleSelected(event);
  }

  toggleSelected(event) {
    if(event.target.nextElementSibling.classList.contains('selected')){
      event.target.nextElementSibling.classList.remove('selected');
    }
    else {
      event.target.nextElementSibling.classList.add('selected');
    }
  }

  selectOnConnect(targets){
    targets.forEach(function(elem){
      if (elem.checked){
        elem.nextElementSibling.classList.add('selected')
      }
    });
  }
}
