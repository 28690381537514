import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    this.setLocal();
  }

  setLocal() {
    let width = window.screen.width
    if (width > 640){
      $('#formResetPassword').attr("data-remote", "false");
    }
  }
}
