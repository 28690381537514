import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
  connect(){
    this.initializeSlideSocialEvent()
  }

  initializeSlideSocialEvent(){
    new Splide('.splide-social-event', {
      rewind: true,
      perPage: 4,
      pagination: false,
      width: '100%',
      autoplay: true,
      arrows: false,
      autoWidth: true,
      padding: '1.5rem',
      gap: '2rem',
      breakpoints: {
        480: {
          perPage: 1,
        },
        640: {
          perPage: 1,
        },
        960: {
          autoWidth: false,
          perPage: 2,
        },
        1024: {
          autoWidth: false,
          perPage: 3,
        },
        1280: {
          autoWidth: false,
          perPage: 3,
        },
        1536: {
          autoWidth: false,
          perPage: 4,
        },
        1920: {
          autoWidth: false,
          perPage: 4,
        },
      },
    } ).mount();
  }
}
