import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['venueInput']

  static values = {
    url: String
  }

  selectVenue() {
    
    if (!(this.venueInputTarget.value === "")) {
      document.getElementById('toggle-venue-form-submit').click()
    }
  }

  xloadVenue(){
    fetch(this.urlValue)
    .then((response) => response.text())
    .then((content) => {
      const modal = $('#js-appmodal')
      modal.find('.modal-content').html(content)
      modal.modal('show')
      modal.on('ajax:error', (event) => {
        let [ , , xhr] = event.detail
        modal.find('.modal-content').html(xhr.response)
      });
      modal.on('ajax:success', () => {
        modal.modal('hide')
      });
    });

  }

  loadVenue(event){
    fetch(event.target.attributes["data-link"].value)
    .then((response) => response.text())
    .then((content) => {
      $('#toggleVenue').html(content)
    });
  }  
}
