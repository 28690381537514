import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")

import Select2 from "select2"


export default class extends Controller {
  connect() {
    let lastValue = document.getElementById("weekdays").getAttribute("data-last");
    $('#weekdays').select2({
      tags: true,
      tokenSeparators: [',', ' '],
      multiple:true,
      placeholder: 'Seleccioná el/los días de la semana',
      width: '100%'
    });

    if (lastValue === null) {
   } else {
    let arrayValue = lastValue.split(',')
    $('#weekdays').val(arrayValue);
    $('#weekdays').trigger('change');
   }
  }
}