import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["datesTooltip", "postulationsTooltip"];

  showTooltip(e) {
    const current = e.currentTarget.dataset.current;
    const tooltip = this[`${current}TooltipTarget`];
    tooltip.style.display = "block";
    setTimeout(() => (tooltip.style.opacity = 1), 250);
  }
  hideTooltip(e) {
    const current = e.currentTarget.dataset.current;
    const tooltip = this[`${current}TooltipTarget`];
    tooltip.style.opacity = 0;
    setTimeout(() => (tooltip.style.display = "none"), 250);
  }
}
