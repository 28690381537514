import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    input: String,
  }

  colorize(e) {
    if (e.target.value == "") {
      e.target.classList.remove('selected')
    }
    else {
      e.target.classList.add('selected')
    }
  }

  cancel_filter() {
    let inputs = document.querySelectorAll(`[data-name^=${this.inputValue}]`)
    let button = document.getElementById('button-search-filter')
    inputs.forEach(input => input.checked = false)    
    button.click()
  }

  search_locality() {
    
    let valueProvince = document.getElementById('filter_province').value
    let locality = document.getElementById('musical-project-locality')
    let loadLocality = document.getElementById('filter_locality')
    $.ajax({
      url: 'professional_profile/load_localities',
      data: {
        'selected_province': valueProvince
      },
      success:  (result) => {
        loadLocality.replaceChildren()
        let optionBlank = document.createElement('option')
            optionBlank.value = ''
            optionBlank.text = ''
        let tmp = result.map(element => {
          let option = document.createElement('option')
          option.value = element
          option.text = element
          return option
        });
        loadLocality.append(optionBlank,...tmp)
      }
    })
    locality.classList.remove('hidden')

  }
}