import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    identifier: String,
  };

  connect() {
    if (window.location.href.includes(`#${this.identifierValue}`)) {
      this.element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
}
