import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
 
  connect(){ 
    this.initializeSlideSocialEvent()
  }


  initializeSlideSocialEvent(){
    var splide = new Splide( '.splide-members', {
      rewind: true,
      type: 'loop',
      focus: 'center',
      arrows: false,
      perMove: 7,
      perPage: 7,
      breakpoints: {
        640: {
          perMove: 1,
          perPage: 1,
        },
        1000: {
          perMove: 3,
          perPage: 3
        },
        1400: {
          perMove: 5,
          perPage: 5,
        },
      }
    } );
    splide.mount();
  }
}