import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input' ]
  static values = { inputMobile: String,
                    inputDesktop: String }
  

  handleInputMobile(){
    var inputMobile = document.getElementsByClassName(this.inputMobileValue)[0]
    var inputDesktop = document.getElementsByClassName(this.inputDesktopValue)[0]
    if (inputDesktop != null) inputDesktop.checked = inputMobile.checked
  }

  handleInputDesktop(){
    var inputMobile = document.getElementsByClassName(this.inputMobileValue)[0]
    var inputDesktop = document.getElementsByClassName(this.inputDesktopValue)[0]
    inputMobile.checked = inputDesktop.checked 
  }
}
