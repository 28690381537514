import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    action: Boolean
  }

  connect() {
    this.addCloseModalEventListeners()
  }

  toggleModal() {
    const body = document.querySelector('body')
    this.element.classList.toggle('opacity-0')
    this.element.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
  }

  addCloseModalEventListeners() {
    let controller = this

    document.querySelector('.modal-overlay').addEventListener('click', this.toggleModal)
    document.onkeydown = function (e) {
      if (e.key === "Escape" && document.body.classList.contains('modal-active')) {
        controller.toggleModal()
      }
    }
  }
}